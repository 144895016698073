import { MediumParagraph, Paragraph, Title } from "../../../components/atoms";
import { BorderedCard, ClashTemplate, VoteModal } from "../../../components/molecule";
import uthred from "../../../assets/img/uthred.png";
import vayne from "../../../assets/img/vayne.svg";
import mordekaiser from "../../../assets/img/mordekaiser.svg";
import summonerSpell1 from "../../../assets/img/summonerSpell1.svg";
import summonerSpell2 from "../../../assets/img/summonerSpell2.svg";
import { useEffect, useState } from "react";
import top from "../../../assets/roles/top.svg";
import jungle from "../../../assets/roles/jungle.svg";
import mid from "../../../assets/roles/mid.svg";
import adc from "../../../assets/roles/adc.svg";
import support from "../../../assets/roles/support.svg";
import { getClashList } from "../../../api/league/clash/clashDisponibility";
import { toast } from "react-toastify";
import { getAllVotes, sendVotesCreation } from "../../../api/votes/votes";
import { useUserContext } from "../../../context";

export default function ClashPage() {

    const { summoner } = useUserContext();


    const [types, setTypes] = useState([
        "Enchanteurs",
        "Mages",
        "Bruisers",
    ]);



    const [positions, setPositions] = useState([
        "TOP",
        "JUNGLE",
        "MID",
        "ADC",
        "SUPPORT",
    ])

    const [clashs, setClashs] = useState([]);
    const [actualClash, setActualClash] = useState({});

    const [sliderState, setSliderState] = useState(0);
    const [voteData, setVoteData] = useState({});
    const [allVotes, setAllVotes] = useState([]);

    const [voteModalOpen, setVoteModalOpen] = useState(false);

    const handleSlider = (param) => {
        if (sliderState === 0 && param === false) {
            return
        } else if (sliderState === clashs.length && param === true) {
            return
        } else {
            const slider = document.getElementById("slider");
            if (param === true) {
                setSliderState(sliderState + 1);
                slider.style.transform = `translateX(-${sliderState + 1 * 100}%)`;
                setActualClash(clashs[sliderState + 1]);
            } else {
                setSliderState(sliderState - 1);
                setActualClash(clashs[sliderState - 1]);
                slider.style.transform = `translateX(-${(sliderState - 1) * 100}%)`
            }
        }
    };

    const handleVotesItem = async () => {
        const votes = await getAllVotes();
        if (votes.error === false) {
            setAllVotes(votes.data);
        } else {
            toast.error(votes.message);
        }
    }


    const getAllClashs = async () => {
        const response = await getClashList()
        if (response.error === false) {
            setClashs(response.data)
        } else {
            toast.error(response.message)
        }
    }

    const filterVotes = () => {
        const filteredVotes = allVotes?.filter((vote) => {
            return vote?.clashId === actualClash?.id
        })

        // get all items in vote where poste is "TOP"
        const topItems = filteredVotes?.filter((item) => {
            return item?.poste === "TOP"
        })
        const jungleItems = filteredVotes?.filter((item) => {
            return item?.poste === "JUNGLE"
        }
        )
        const midItems = filteredVotes?.filter((item) => {
            return item?.poste === "MID"
        }
        )
        const adcItems = filteredVotes?.filter((item) => {
            return item?.poste === "ADC"
        }
        )
        const supportItems = filteredVotes?.filter((item) => {
            return item?.poste === "SUPPORT"
        }
        )

        const lanes = [topItems, jungleItems, midItems, adcItems, supportItems]


        // find all unique summonerId in jungleItems
        const uniqueTopItems = [...new Set(topItems?.map((item) => item?.summonerId))]
        const uniqueJungleItems = [...new Set(jungleItems?.map((item) => item?.summonerId))]
        const uniqueMidItems = [...new Set(midItems?.map((item) => item?.summonerId))]
        const uniqueAdcItems = [...new Set(adcItems?.map((item) => item?.summonerId))]
        const uniqueSupportItems = [...new Set(supportItems?.map((item) => item?.summonerId))]

        const uniqueTable = [uniqueTopItems, uniqueJungleItems, uniqueMidItems, uniqueAdcItems, uniqueSupportItems]


        let topVotesCount = [];
        let jungleVotesCount = [];
        let midVotesCount = [];
        let adcVotesCount = [];
        let supportVotesCount = [];

        const votesCount = [topVotesCount, jungleVotesCount, midVotesCount, adcVotesCount, supportVotesCount]

        lanes.forEach((lane, index) => {
            uniqueTable[index]?.forEach((item) => {
                const count = lane?.filter((vote) => {
                    return vote?.summonerId === item
                })
                const summoner = lane?.find((vote) => {
                    return vote?.summonerId === item
                })
                summoner.votes = count.length;

                votesCount[index].push(summoner)
            })
        })



        return votesCount
    }


    useEffect(() => {
        filterVotes()
    }, [allVotes, actualClash])

    useEffect(() => {
        getAllClashs()
        handleVotesItem()
    }, [])

    const getRole = (role) => {
        switch (role) {
            case "TOP":
                return top;
            case "JUNGLE":
                return jungle;
            case "MID":
                return mid;
            case "ADC":
                return adc;
            case "SUPPORT":
                return support;
            default:
                return;
        }
    }

    useEffect(() => { setActualClash(clashs[0]) }, [clashs])

    const formatDate = (date) => {
        const data = date.split("T")[0];
        const day = data.split("-")[2];
        const month = data.split("-")[1];
        const year = data.split("-")[0];
        return `${day}/${month}/${year}`;

    };

    const confirmVote = async (data) => {
        const response = await sendVotesCreation(data)
        if (response.error === false) {
            toast.success(response.message)
            handleVotesItem()
            setVoteModalOpen(false)
        } else {
            toast.error(response.message)
        }
    }

    const isVoted = (summonerId, poste, clashId) => {
        const vote = allVotes?.find((vote) => {
            return vote?.summonerId === summonerId && vote?.poste === poste && vote?.voterId === summoner?.id && vote?.clashId === clashId
        })
        if (vote) {
            return true
        } else {
            return false
        }
    }

    return (
        <ClashTemplate>
            <VoteModal isOpen={voteModalOpen} setIsOpen={setVoteModalOpen} data={voteData} confirm={confirmVote} />
            <div className="flex justify-center">
                <div className="max-w-[1500px] mt-20 mb-40 w-full flex flex-col items-center">
                    {clashs?.length > 0 ?
                        <><div className="w-full border-[3px] flex flex-col border-yellow rounded-lg min-h-[200px] bg-yellow">
                            <div className="flex items-center border-b-[3px] border-b-gray-medium h-[80px] w-full  gap-4">
                                {positions?.map((position, index) => (
                                    <div key={index} className="flex bg-gray first:rounded-tl-md last:rounded-tr-md w-full h-full  justify-center items-center">
                                        <p className="font-boogaloo text-3xl">{position}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="flex h-full h-full w-full bg-yellow gap-4">
                                {filterVotes()?.map((position, index) => (
                                    <div key={index} className="flex flex-col gap-4 w-full h-full first:rounded-bl-md last:rounded-br-md bg-gray  items-center px-3 pt-3">
                                        {position.sort((a, b) => b.votes > a.votes ? 1 : -1).map((item, index) => (
                                            <div key={index} className={`${item.votes === position[0].votes ? "bg-yellow" : "bg-white"}  w-full h-20  first:bg-yellow border-2 border-gray-medium rounded-lg flex`}>
                                                <img className="h-full aspect-square rounded-full ml-2 py-1 object-cover" src={uthred} alt="uthred" />
                                                <div className="w-full flex flex-col items-center  justify-center gap-1">
                                                    <p className="font-boogaloo text-gray-medium text-2xl">{item.Summoner.name}</p>
                                                    <p className="font-poppins text-black"><strong>{item.votes}</strong> votes</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                            <BorderedCard className="w-1/3 h-16 mt-10 flex items-center">
                                <div onClick={() => handleSlider(false)} className={`h-full w-[15%] cursor-pointer flex items-center justify-center duration-200 ${sliderState !== 0 ? "opacity-100" : "opacity-0 cursor-default pointer-events-none"}`}>
                                    <svg className="!text-gray-medium" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M15.125 21.1L6.7 12.7q-.15-.15-.212-.325q-.063-.175-.063-.375t.063-.375q.062-.175.212-.325l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.862q0 .513-.375.888t-.875.375q-.5 0-.875-.375Z" /></svg>
                                </div>
                                <div className="h-1/2 w-[1px] bg-gray-medium border border-gray-medium"></div>
                                <div className="h-full w-full relative overflow-hidden">
                                    <div id="slider" className={`w-full h-full flex duration-200 }`}>
                                        {clashs.map((clash, index) => (
                                            <div key={index} className="w-full shrink-0 h-full flex items-center justify-around">
                                                <MediumParagraph className="font-poppins">Coupe {clash.nameKey}  |  Jour {clash.nameKeySecondary}</MediumParagraph>
                                                <MediumParagraph className="font-poppins">{formatDate(clash?.startTime)}</MediumParagraph>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="h-1/2 w-[1px] bg-gray-medium border border-gray-medium"></div>
                                <div onClick={() => handleSlider(true)} className={`h-full w-[15%] cursor-pointer flex items-center justify-center duration-200 ${sliderState === clashs.length - 1 ? "opacity-0 cursor-default pointer-events-none" : "opacity-100"}`}>
                                    <svg className="!text-gray-medium rotate-180" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M15.125 21.1L6.7 12.7q-.15-.15-.212-.325q-.063-.175-.063-.375t.063-.375q.062-.175.212-.325l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.862q0 .513-.375.888t-.875.375q-.5 0-.875-.375Z" /></svg>
                                </div>
                            </BorderedCard>
                            <h2 className="font-boogaloo text-[42px] text-gray-medium text-center mt-16 mb-12">Créer ton équipe</h2>
                            <div className="w-full grid grid-cols-12 gap-5">
                                {actualClash?.ClashDisponibility?.map((item, index) => (
                                    item.status === 1 && item?.Summoner?.mainRole && <BorderedCard key={index} className="col-span-3">
                                        <div className="w-full flex pl-2 pt-2 pb-2 ">
                                            <img src={uthred} className="aspect-square object-cover h-32 rounded-full" alt="" />
                                            <div className="flex py-2 w-full justify-center h-32 gap-3">
                                                <div className="flex flex-col h-full">
                                                    <div className="flex items-center justify-center h-1/2">
                                                        <Title>{item?.Summoner?.name}</Title>
                                                    </div>
                                                    {item?.Summoner?.mainRole && <div className="flex justify-center  items-center h-1/2 gap-1">
                                                        {<img onClick={() => { setVoteModalOpen(true); setVoteData({ id: item?.Summoner?.id, name: item?.Summoner?.name, poste: item?.Summoner?.mainRole, clashId: item?.clashId }) }} alt="role" className={`hover:bg-yellow-light rounded-lg cursor-pointer p-3 ${isVoted(item?.Summoner?.id, item?.Summoner?.mainRole, item?.clashId) && "bg-yellow"}`} src={getRole(item?.Summoner?.mainRole)} />}
                                                        {item?.Summoner?.secondaryRole && <><span className="w-[2px] rounded-full h-1/2 bg-gray-medium"></span>
                                                            <img onClick={() => { setVoteModalOpen(true); setVoteData({ id: item?.Summoner?.id, name: item?.Summoner?.name, poste: item?.Summoner?.secondaryRole, clashId: item?.clashId }) }} alt="role" className={`hover:bg-yellow-light rounded-lg cursor-pointer p-3 ${isVoted(item?.Summoner?.id, item?.Summoner?.secondaryRole, item?.clashId) && "bg-yellow"}`} src={getRole(item?.Summoner?.secondaryRole)} /> </>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        {item?.Summoner?.ChampionType?.length > 0 && <div className="w-full flex flex-wrap px-3 items-center mt-5 mb-3 gap-3">
                                            {item?.Summoner?.ChampionType.map((type, index) => (
                                                <div key={index}
                                                    className="bg-yellow rounded-full py-1 px-3">
                                                    <p className="text-sm font-poppins">{type.ChampionType.name}</p>
                                                </div>
                                            ))}
                                        </div>}
                                        {item?.Summoner?.clashCard?.description && <div className="h-full w-full max-h-[144px] flex items-center overflow-hidden mb-1 ">
                                            <div className="w-full h-full mt-2 px-4 pb-3 overflow-auto">
                                                <Paragraph className="pt-1 overflow-auto">{item?.Summoner?.clashCard?.description}</Paragraph>
                                            </div>
                                        </div>}
                                    </BorderedCard>)
                                )}
                            </div>
                        </> : <Title>Il n'y a pas de clash de prévu pour le moment !</Title>}
                </div>
            </div>
        </ClashTemplate>
    )
}