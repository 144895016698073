import {
  Route,
  BrowserRouter as Router,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import Login from "../pages/login.page";
import Register from "../pages/register.page";
import Home from "../pages/home.page";
import League from "../pages/league/teams/league.page";
import Summoner from "../pages/league/summoner/summoner.page";
import Profile from "../pages/profile/profile.page";
import ClashPage from "../pages/league/clash/clash.page";
import Minecraft from "../pages/minecraft/minecraft.page";
import Admin from "../pages/admin/admin.page";
import { useUserContext } from "../context";
import Page404 from "../pages/404.page";

export function DefaultRouter() {

  const { user } = useUserContext();

 
  return (  
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={user?.role !== "ADMIN" && user?.role !== "SUPERADMIN" ? <Page404 /> : <Admin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/league/teams" element={<League />} />
        <Route path="/league/summoner" element={<Summoner />} />
        <Route path="/league/clash" element={<ClashPage />} />
        <Route path="/minecraft" element={<Minecraft />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}
