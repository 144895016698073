
import Template from "../components/molecule/Template";


export default function Home() {
     
  return (
    <Template>
      <h1>Home</h1>
    </Template>
  );
}
