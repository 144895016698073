import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import jungle from "../../../assets/roles/jungle.svg";
import mid from "../../../assets/roles/mid.svg";
import top from "../../../assets/roles/top.svg";
import adc from "../../../assets/roles/adc.svg";
import support from "../../../assets/roles/support.svg";
import { Paragraph } from "../../atoms";
import { useUserContext } from "../../../context";

export function SummonerHeader() {
  const { user, smurf, summoner } = useUserContext();

  const [searchedSummoner, setSearchedSummoner] = useState("");

  useEffect(() => {
    setSearchedSummoner(summoner)

  }, [summoner, smurf])

  const getRoleIcon = (role) => {
    switch (role) {
      case "TOP":
        return top;
      case "JUNGLE":
        return jungle;
      case "MID":
        return mid;
      case "ADC":
        return adc;
      case "SUPPORT":
        return support;
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   setSummonerName(searchedSummoner.name);
  // }, [searchedSummoner]);

  return (
    <>
      <div className="w-full  h-[80px] flex justify-center bg-gray relative">
        <div className="h-full w-[30%] bg-yellow absolute left-0 top-0"></div>
        <div className="flex max-w-[1500px] 2xl:px-0 px-[5%] h-full w-full flex">
          <div className="h-full w-[32.3%] 2xl:w-[41.3%] flex items-center relative bg-yellow justify-between">
            {user ?<Link to="/league/clash" className={`2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center hover:border-none summoner-triangle-full-right group 2xl:bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-black 2xl:hover:text-yellow group-bg uppercase`}>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 first-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 first-triangle-two cursor-pointer "></div>
              CLASH
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-fourth cursor-pointer "></div>
            </Link> :
            <div className="h-full 2xl:w-full w-[60px]"></div>}
            <Link to="/league/teams" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center summoner-triangle-full-right group 2xl:bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-black 2xl:hover:text-yellow group-bg uppercase">
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 second-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 second-triangle-two cursor-pointer "></div>
              ÉQUIPES
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 second-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 second-triangle-fourth cursor-pointer "></div>
            </Link>
          </div>
            <div className="box2 2xl:hidden"></div>
          <div className="h-full w-[67.7%] flex items-center justify-between 2xl:pl-20">
            {searchedSummoner?.mainRole ?
              <div
                className={`flex items-center gap-1.5`}
              >
                <img src={getRoleIcon(searchedSummoner?.mainRole)} alt="role" />
                <Paragraph className="font-medium text-[18px]">
                  {searchedSummoner?.mainRole}
                </Paragraph>
              </div> : !searchedSummoner?.mainRole && searchedSummoner.type === "main" && searchedSummoner?.userId === user?.id ? 
              <Link
                    className="underline font-poppins text-gray-medium font-medium text-[18px]"
                    to="/profile"
                  >
                    A définir{" "}
                  </Link>
               :  !searchedSummoner?.mainRole && searchedSummoner.type === "main" && searchedSummoner?.userId !== user?.id  ? 
               <p className="font-poppins text-gray-medium font-medium text-[18px]">A définir</p>
               :!searchedSummoner?.mainRole &&
                   searchedSummoner?.type === "secondary" && 
                   <div className="h-7 w-16 opacity-0"></div>
            }
            <form action="/league/summoner">
              <input
                name="name"
                className={`text-[30px] font-boogaloo text-center hover:bg-white hover:border-2 hover:border-yellow rounded-lg ${searchedSummoner?.name?.length
                    ? "bg-gray"
                    : "bg-white border-2 border-yellow  "
                  } focus:bg-white focus:outline-none focus:border-2 focus:border-yellow focus: `}
                defaultValue={searchedSummoner?.name}
                placeholder="Nom d'invocateur"
              />
              <input type="submit" hidden />
            </form>
            {smurf ? <form action="/league/summoner">
              <input name="name" hidden value={smurf?.name} />
              <input
                type="submit"
                className={`font-poppins italic underline text-[#019ABA] bg-transparent pr-1.5 border-none  ${!smurf ? "opacity-0 cursor-none" : "cursor-pointer"
                  } `}
                value={smurf?.name}
              />
            </form> : <div className="w-20"></div>}
          </div>
        </div>
      </div>
    </>
  );
}
