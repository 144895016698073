import joinClasses from "../../../helpers/joinClasses";
import cross from "../../../assets/icons/cross.svg";
import checkmark from "../../../assets/icons/checkmark.svg";

export function   TeamRadio({ onChange = () => { }, className = "", checked, name }) {

  return (
    <div className={`relative w-1/2 h-[34px] bg-white border-[1.5px]  flex items-center justify-between ${checked ? "border-yellow " : "border-gray-medium/50"}  rounded-lg`}>
      <label className={`font-poppins uppercase text-sm  pl-2.5 ${checked ? "text-gray-medium" : "text-gray-medium/50"}`}>{name}</label>
      {checked ? (
        <img
          src={cross}
          alt="cross"
          className="pr-2.5"
        />
      ) : (
        <img
          src={checkmark}
          alt="checkmark"
          className="pr-2.5"
        />
      )}
      <input type="checkbox" onChange={onChange} checked={checked} className="w-full h-full absolute opacity-0" />
    </div>
  )
}