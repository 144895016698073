import { Paragraph } from "../../atoms"

export function SummonerBandeauInfo({title, state, className = "", setState = () => {}, name}) {
    return (
<div className="w-full h-20 bg-yellow border-y-2 border-gray-medium flex justify-center">
                <div className="max-w-[1500px] 2xl:px-0 px-[5%] w-full h-full flex items-center justify-between">
                    <h3 className="font-boogaloo text-[30px] text-gray-medium uppercase">{title}</h3>
                    <div className="flex cursor-pointer items-center gap-3" onClick={() => {setState(name, !state)}}>
                        <Paragraph>{state ? "Réduire" : "Afficher"}</Paragraph>
                        <svg width="32" height="32" viewBox="0 0 24 24" className={` duration-200 ${state ? "rotate-180" : "rotate-0"}`}><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6 9l6 6l6-6"/></svg>
                    </div>
                </div>
            </div>

    )

}