import React, { useState, useEffect } from 'react';
import { Title } from '../../components/atoms';
import Template from '../../components/molecule/Template';


export default function Minecraft() {
  return (
    <Template>
      <Title className='text-4xl text-center mt-10'>COMING SOON</Title>
      </Template>
  );
}

