import "../style.css";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../context";
import { useEffect, useState } from "react";
import biscotte from "../../../assets/img/logo/biscotte.png";
import "./style.css"

export default function Header() {

  const { user } = useUserContext();

  const [summonerName, setSummonerName] = useState("");

  const getSummonerName = () => {
    if (user && user) {
      // find summoner.type === "main"
      const mainAccount = user?.Summoner?.find((summoner) => summoner.type === "main");
      setSummonerName(mainAccount.name);
    }
  }

  useEffect(() => {
    getSummonerName();
  }, [user])

  return (
    <>
      <div className="w-full h-[80px] flex justify-center bg-gray  relative">
        <div className="h-full w-[25%] bg-gray absolute 2xl:block hidden left-0 top-0"></div>
        <div className="h-full 2xl:w-[25%] w-[5.1%]  bg-yellow absolute right-0 top-0"></div>

        <div className="flex max-w-[1500px] h-full w-full flex 2xl:px-0 px-[5%]">
          <div className="h-full w-[32.3%] bg-gray z-10 flex items-center">
            <Link to="/">
              <div className="flex items-center gap-5">
                <img className="w-10" alt="logo" src={biscotte} />
                <h1 className="font-boogaloo text-4xl">Biscotte Family</h1>{" "}
              </div>
            </Link>
          </div>
            <div className="box 2xl:hidden"></div>
          <div className="h-full w-[67.7%] bg-yellow 2xl:bg-transparent relative flex items-center justify-between">
            {!summonerName ? <Link to="/league/teams" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
              <div className="h-full w-20 absolute  2xl:block hidden bg-yellow left-0 z-20 first-triangle-two cursor-pointer "></div>
              League Of Legends
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-third cursor-pointer "></div>
            </Link> : <a href={`/league/summoner?name=${summonerName}`} className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 first-triangle-two cursor-pointer "></div>
              League Of Legends
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-third cursor-pointer "></div>
            </a>}
            {user && user ? <><Link to="/minecraft" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 second-triangle-two cursor-pointer "></div>
              Minecraft
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 second-triangle-third cursor-pointer "></div>
            </Link>
              <Link to="/profile" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 third-triangle-two cursor-pointer "></div>
                Profil
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 third-triangle-third cursor-pointer "></div>
              </Link></> : <>
              <Link to="/login" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 third-triangle-two cursor-pointer "></div>
                Connexion
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 third-triangle-third cursor-pointer "></div>
              </Link> <Link to="/register" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center triangle-full-right group bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium 2xl:hover:text-yellow hover:text-black group-bg uppercase">
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 third-triangle-two cursor-pointer "></div>
                Inscription
                <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 third-triangle-third cursor-pointer "></div>
              </Link>
            </>}
          </div>
        </div>
      </div>
    </>
  );
}
