import axios from 'axios';

export async function sendVotesCreation(payload) {
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/vote/create`,
        payload
    );
        return response.data;
}

export async function getAllVotes() {
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/vote`
    );
        return response.data;
}



