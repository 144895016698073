import { Gellule, LongButton, NewAuthInput, Paragraph, TextArea } from "../../atoms";
import { ProfileCard } from "../../molecule";
import upload from "../../../assets/icons/upload.svg";
import { useEffect, useState } from "react";
import { getChampionType, getChampionTypeForASummoner, updateClashCard } from "../../../api/league/championType/championType";
import { toast } from "react-toastify";
import { useUserContext } from "../../../context";


export function ClashCard({ top }) {

    const {summoner, setSummoner} = useUserContext();

    const [avatar, setAvatar] = useState(false);
    const [type, setType] = useState([]);
    const [typeList, setTypeList] = useState([]);
    
    const [isTypeOpen, setIsTypeOpen] = useState(false);
    const [description, setDescription] = useState("");

    const allChampionType = async () => {
        const response = await getChampionType();
        if (response.error === false) {
            setTypeList(response.data);
        }else {
            toast.error(response.message);
        }
        const response2 = await getChampionTypeForASummoner(summoner.id);
            if (response2.error === false) {
                setType(response2.data);
                let temp = response.data;
                response2?.data?.forEach(element => {
                    temp?.forEach(type => {
                        // if element.id === type.id
                        if (element.id === type.id) {
                            // remove type from temp
                            temp.splice(temp.indexOf(type), 1);
                        }
                    });
                });
                setTypeList(temp);
            }else{
                toast.error(response2.message)
            }
    };

    useEffect(() => {
        allChampionType()
    },[summoner]);

    const handleSelectType = (item) => {
        setType([...type, item]);
        setTypeList(typeList.filter((role) => role !== item));
        setIsTypeOpen(false);
    };

    const saveClashCard = async () => {
        const token = localStorage.getItem("bifam-token");
        const response = await updateClashCard({token, championType : type, description});
        if (response.error === false) {
            setSummoner(response.data);
            toast.success(response.message);
        }
    }

    const handleTextAreaLength = (e) => {
        if (e.target.value.length <= 200) {
            setDescription(e.target.value);
        }
    };

    return (
        <div>
            <ProfileCard title="Profil de clash" className2="!items-start grid grid-cols-12 gap-12">
                <div className="border-[3px] cursor-pointer border-yellow rounded-full aspect-square flex items-center justify-center col-span-4 bg-white relative">
                    <div className="flex flex-col rounded-full cursor-pointer items-center gap-2">
                        <img src={upload} alt="" className="cursor-pointer" />
                        <Paragraph className="text-center cursor-pointer rounded-full">Ajouter une image <br /> de profil</Paragraph>
                    </div>
                    <input type="file" className="opacity-0 cursor-pointer z-20 absolute top-0 left-0 w-full h-full" />
                    {avatar && <img src={top} className="absolute cursor-pointer w-full h-full z-10 rounded-lg" alt="" />}
                </div>
                <div className="flex flex-col col-span-8 col-start-5 gap-4 h-full">
                    <TextArea maxLength="230" onChange={(e) => {handleTextAreaLength(e)}} defaultValue={summoner?.clashCard?.description} placeholder="Description" classNameContainer="h-full h-[150px]" className="!h-full" />
                    <div className="flex w-full justify-between gap-10">
                        <div className={`flex flex-col w-full ${type.length > 0 && "gap-3"}`}>
                            <NewAuthInput isOpen={isTypeOpen} setIsOpen={setIsTypeOpen} onClick={() => { typeList.length > 0 && setIsTypeOpen(true)}} type="select"  placeholder="Types de champions" className="relative" >
                                {typeList?.length === 0 && <div className="absolute top-0 left-0 bg-black opacity-30 h-full w-full rounded-lg"></div>}
                                <ul className={`flex flex-col bg-white rounded-lg absolute top-0 left-0 border-[3px] border-gray-medium w-full ${(!isTypeOpen) && "hidden"}`} >
                                    {typeList.sort((a, b) => a.index > b.index ? 1 : -1).map((item, index) => (
                                        <li key={index} className={`hover:bg-yellow hover:text-gray-medium ${index === 0 && "hover:rounded-t-md"} ${index === typeList.length - 1 && "hover:rounded-b-md"} duration-200 pl-3 py-1`} onClick={() => handleSelectType(item)}>
                                            {item?.name}
                                        </li>
                                    ))}
                                </ul>
                            </NewAuthInput>
                            <div className="flex items-center gap-3 flex-wrap">
                                {type.map((item, index) => (
                                    <Gellule list={typeList} setList={setTypeList} key={index} index={index} state={type} setState={setType} item={item} >{item?.name}</Gellule>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <LongButton className="col-span-12 -mt-5" onClick={() => {saveClashCard()}}>Enregistrer</LongButton>
            </ProfileCard>
        </div>
    )
}