import {  useState } from "react";
import Bandeau from "../components/atoms/Bandeau";
import { Card } from "../components/molecule";
import { AuthInput, LongButton } from "../components/atoms";
import LongWhiteButton from "../components/atoms/buttons/LongWhiteButton";
import toaster from "../assets/icons/toaster.svg";
import Template from "../components/molecule/Template";
import { SmallText } from "../components/atoms";
import { useUserContext } from "../context";
import { useNavigate } from "react-router-dom";

export default function Login() {

  const { loading, loginTheUser, setLoading } = useUserContext();


  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await loginTheUser(credentials)
    if (response === true) {
      setLoading(false);
      navigate("/");
    }
  };




  return (
    <Template className="flex justify-center items-center h-full">
      <div className="flex h-full min-h-[600px] w-screen">
        <Card className="flex flex-col items-center w-[90%] px-3" title={"Se connecter"} >
          <form onSubmit={(e) => handleSubmit(e)}
            className="w-full">

            <div className="flex flex-col items-center mt-[30px] gap-6 w-full">
              <AuthInput
                placeholder={"Adresse email"}
                type={"email"}
                autoComplete="email"
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e.target.value })
                }
              />
              <AuthInput
                placeholder={"Mot de passe"}
                type={"password"}
                autoComplete="current-password"
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
            </div>
            <div className="flex w-full  m-auto justify-end mt-2 ">
              <a
                href="/password"
                className="underline text-sm font-poppins font-medium text-right text-gray-medium"
              >
                Mot de passe oublié ?
              </a>
            </div>
            <LongButton
              type="submit"
              className="mt-5"
            >
              {loading ? "Connexion..." : "Se connecter"}
            </LongButton>
          </form>
          <div className="flex flex-col items-center w-full  m-auto mt-16  gap-1.5">
            <div className="flex items-center w-full gap-2">
              <div className="border-b bg-black h-[1px] border-black w-full" />
              <SmallText className="!min-w-max">Tu n'as pas encore de compte ?</SmallText>
              <div className="border-b bg-black h-[1px] border-black w-full" />
            </div>
            <LongWhiteButton to="/register">
              <img src={toaster} alt="biscotte-family-toaster" />Deviens une
              biscotte
            </LongWhiteButton>
          </div>
        </Card>
      </div>
      <Bandeau className="container1 " />
      <Bandeau className="container2" />
    </Template>
  );
}
