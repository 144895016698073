import Template from "../../components/molecule/Template";
import { LongButton, NewAuthInput } from "../../components/atoms";
import top from "../../assets/roles/top.svg";
import { Card } from "../../components/molecule";
import { ClashCard, LeagueCard, Minecraft } from "../../components/organisms";
import Bandeau from "../../components/atoms/Bandeau";
import { useUserContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function Profile() {

    const { user, setUser, setStatus } = useUserContext();
    const navigate = useNavigate();



    const logoutFromApp = () => {
        localStorage.removeItem("bifam-token");
        setUser(null);
        setStatus("nothing");
        navigate("/");
        localStorage.setItem("bifam-connected", false);
    };

    return (
        <Template className="flex flex-col items-center">
            <h2 className="font-boogaloo text-[42px] text-gray-medium text-center mt-12">Bienvenue sur votre profil</h2>
            <section className="w-full max-w-[1500px] relative flex justify-between items-start mt-12 mb-20">
                <Bandeau className="container3" />
                <div className="w-[65%] flex flex-col gap-8">
                    <div className="flex justify-between mt-8 w-full">
                        <LeagueCard user={user} />
                        <Minecraft user={user} />
                    </div>
                    <ClashCard
                        top={top}
                    />
                </div>
                <div className="w-[1px] bg-gray-medium h-[930px] border border-gray-medium"></div>
                <div className=" mt-8 sticky top-8 mb-8">
                    <Card title="Informations personnelles" className="sticky !h-[530px]">
                        <NewAuthInput defaultValue={user?.firstName} placeholder="Prénom" className="mb-4"></NewAuthInput>
                        <NewAuthInput defaultValue={user?.lastName} placeholder="Nom" className="mb-4"></NewAuthInput>
                        <NewAuthInput defaultValue={user?.email} type="email" placeholder="Adresse mail" className="mb-4"></NewAuthInput>
                        <div className="flex flex-col items-start gap-5 w-full  m-auto mt-2 ">
                            <a
                                href="/password"
                                className="underline text-sm font-poppins font-medium text-right text-gray-medium"
                            >
                                Changer de mot de passe
                            </a>
                        </div>
                        <LongButton >Enregistrer</LongButton>
                        <p onClick={logoutFromApp} className="text-center font-poppins text-gray-medium underline mt-5 font-medium cursor-pointer">Se deconnecter</p>
                    </Card>
                </div>
            </section>
        </Template>
    )
}