import axios from 'axios';
import getFormatedToken from '../getFormattedToken';

export async function adminGetClashs() {
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/clash/upcoming`);
    return response.data;
}

export async function adminChampions() {
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/data/champions`,
        getFormatedToken());
    return response.data;
}

export async function adminQueue() {
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/data/queue`,
        getFormatedToken());
    return response.data;
}

export async function adminSummonerSpells() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_LOCALURL}/data/summonerspell`,
            getFormatedToken());
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function adminChampionsTypes() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_LOCALURL}/data/championtypes`,
            getFormatedToken());
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function adminResetRank() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_LOCALURL}/rank/reset`,
            getFormatedToken());
        return response.data;
    } catch (error) {
        console.log(error);
    }
}