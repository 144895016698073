import { useEffect, useState } from "react";
import mordekaiser from "../../../assets/img/mordekaiser.svg";
import { HistoCard } from "../../../components/molecule";
import { getAllSummoners } from "../../../api/league/summoner/summoner";
import { useUserContext } from "../../../context";
import { TeamTemplate } from "../../../components/molecule/Template/TeamTemplate";

export default function League() {
  const teams = [
    {
      name: "bifam",
      status: false,
      id: 1,
    },
    { name: "quaibab", status: false, id: 2 },
    { name: "wescale", status: false, id: 3 },
  ];

  const [summoners, setSummoners] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(
    new Array(teams.length).fill(false)
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = selectedTeam.map((item, index) =>
      index === position ? !item : item
    );

    setSelectedTeam(updatedCheckedState);
  };

  const getSummoners = async () => {
    const summoners = await getAllSummoners();
    setSummoners(summoners);
  };

  useEffect(() => {
    getSummoners();
  }, []);

  return (
    <TeamTemplate>
      <div className="w-full flex flex-col items-center justify-center px-[5%]">
        <div className="max-w-[1500px] w-full   flex justify-evenly py-16">
          {teams.map((team, index) => {
            return (
              <div
                key={index}
                className={`w-[225px] h-[65px] flex justify-center items-center relative  border-2  text-gray-medium rounded-lg cursor-pointer  ${selectedTeam[index]
                    ? "shadow border-gray-medium bg-yellow"
                    : "bg-gray border-yellow"
                  }`}
              >
                <label className="font-boogaloo text-[26px] uppercase">
                  {team.name}
                </label>
                <input
                  type="checkbox"
                  onChange={() => handleOnChange(index)}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            );
          })}
        </div>
        <div className="max-w-[1500px] w-full flex flex-wrap justify-between gap-6 mb-20">
          {/* if summoners.team = selectedTeams[summoners.team] === true or all selectedTeams value are false*/}
          {summoners?.map(
            (summoner, index) =>
              (summoner.Teams.some((item) => selectedTeam[item]) ||
                selectedTeam.every((item) => item === false)) && (
                summoner?.Game?.length > 0 && <HistoCard
                  summoner={summoner}
                  index={index}
                  key={index}
                />
              )
          )}
        </div>
      </div>
    </TeamTemplate>
  );
}
