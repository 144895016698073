import Footer from "./Footer";
import Header from "./Header/Header";
import joinClasses from "../../helpers/joinClasses";

export default function Template({ className = "", children }) {
  return (
    <div className={`flex flex-col min-h-screen `}>
      <Header />
      
      <div className={joinClasses(className, "")}  style={{ "minHeight": "calc(100vh - 80px)" }}  >
      {children}
      </div>
      <Footer />
    </div>
  );
}
