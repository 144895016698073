import joinClasses from "../../../helpers/joinClasses";

export function SmallText({ children, className = "" }) {
  return (
    <p
      className={joinClasses(className,
        `text-xs font-poppins font-medium text-gray-medium`
      )}
    >
      {children}
    </p>
  );
}
