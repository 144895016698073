import { FooterTitle, FooterLink } from "../atoms";
import discord from "../../assets/socials/discord.svg";
import twitch from "../../assets/socials/twitch.svg";
import youtube from "../../assets/socials/youtube.svg";

export default function Footer() {
  return (
    <div className="w-full bg-gray flex flex-col items-center justify-center px-[5%]">
      <div className="max-w-[1500px] w-full pb-10 border-b border-gray-medium grid grid-cols-2 md:gap-y-0 gap-y-10   md:flex md:justify-between py-10">
        <div className="flex flex-col gap-4 md:items-start items-center">
          <FooterTitle>Informations</FooterTitle>
          <FooterLink href="/a-propos">À propos</FooterLink>
          <FooterLink href="/mentions-legales">Mentions légales</FooterLink>
          <FooterLink href="politique-de-confidentialite">Politique de confidentialité</FooterLink>
          <FooterLink href="/contact">Contact</FooterLink>
        </div>
        <div className="flex flex-col gap-4 md:items-start items-center">
          <FooterTitle>Jeux</FooterTitle>
          <FooterLink href="league">League of Legends</FooterLink>
          <FooterLink>Minecraft</FooterLink>
          <FooterLink>Rocket League</FooterLink>
        </div>
        <div className="flex flex-col gap-4 md:items-start items-center">
          <FooterTitle>Sites</FooterTitle>
          <FooterLink href="https://biscottefamily.fr/">Biscotte Family</FooterLink>
          <FooterLink target="_blank" href="https://masteries.biscottefamily.fr/">Masteries</FooterLink>
        </div>
        <div className="flex flex-col gap-4 md:items-start items-center">
          <FooterTitle>Retrouvez-nous</FooterTitle>
          <div className="flex gap-4">
            <FooterLink className="h-[35px] w-[35px]" href="https://discord.com" target="_blank">
              <div className="w-[35px] h-[35px] hover:bg-[#7289DA] duration-200 bg-yellow rounded-full flex items-center justify-center pt-0.5">
                <img className="" src={discord} alt="discord" />
              </div>
            </FooterLink>
            <FooterLink className="h-[35px] w-[35px]" href="https://www.youtube.com/channel/UCAOf6CQwgJCH4GJsulmWg8g" target="_blank">
              <div className="w-[35px] h-[35px] hover:bg-[#FF0000] duration-200 bg-yellow rounded-full flex items-center justify-center pt-0.5">
                <img src={youtube} alt="youtube" />
              </div>
            </FooterLink>
            <FooterLink className="h-[35px] w-[35px]" href="https://twich.tv/biscottefamily/" target="_blank">
              <div className="w-[35px] h-[35px] hover:bg-[#9146FF] duration-200 bg-yellow rounded-full flex items-center justify-center pt-0.5">
                <img src={twitch} alt="twitch" />
              </div>
            </FooterLink>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col sm:items-center sm:justify-between max-w-[1500px] w-full pt-8 pb-10 gap-4" >
        <p className="font-poppins text-left font-[14px] text-gray-medium">Copyright © {new Date().getFullYear()} <strong> Biscotte Family</strong>, tous droits réservés</p>
        <p className="font-poppins text-left font-[14px] text-gray-medium">Développé par <a target="_blank" rel="noreferrer" href="https://github.com/StarlingSG1" className="font-poppins underline cursor-pointer text-left font-[14px] text-gray-medium hover:text-black duration-200">Starling</a></p>
      </div>

    </div>
  );
}
