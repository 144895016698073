import { Link } from "react-router-dom";
import joinClasses from "../../../helpers/joinClasses";

export default function LongWhiteButton({
  children,
  to,
  className = "",
}) {
  return (
    <Link
      to={to}
      className={joinClasses(
        className,
        `text-yellow font-bold font-poppins flex items-center justify-center gap-5 w-full py-2 bg-white shadow rounded-lg`
      )}
    >
      {children}
    </Link>
  );
}
