import joinClasses from "../../../helpers/joinClasses";
import annie from "../../../assets/img/annie.svg";
import summonerSpell1 from "../../../assets/img/summonerSpell1.svg";
import { Paragraph } from "../../atoms";

export function GameCard({
  className = "",
  index = 1,
  title,
  children,
  assists,
  championName,
  date,
  deaths,
  duration,
  kills,
  queue,
  summonerSpell1,
  summonerSpell2,
  game,
  isOpen
}) {
  return (
    <div
      className={joinClasses(
        className,
        `bg-gray  col-span-12 850:col-span-6 rounded-lg border-[3px] ${
          game?.result ? "border-blue-win" : "border-red-loss"
        } flex odd:flex-row even:flex-row-reverse items-center justify-between px-2 500:px-4 sm:px-10 850:px-2.5 lg:px-[30px] py-5 `
      )}
    >
      <img
        src={game?.champion?.square}
        className="rounded-full 500:h-[95px] h-[72px] aspect-square"
        alt={game?.champion.name}
      />
      <div className={`flex flex-col xl:w-[170px] h-full 500:justify-between justify-center 500:gap-0 gap-2 items-center`}>
        <Paragraph className="text-sm 500:text-base">{game?.Queue?.name}</Paragraph>
        <Paragraph className="font-bold  500:text-xl">{game?.champion?.name}</Paragraph>
        <Paragraph className="text-sm 500:text-base">{game?.kills} / {game?.deaths} / {game?.assists}</Paragraph>
      </div>
      <div className={`flex 850:flex-col sm:flex-row flex-col h-full 850:gap-0 sm:gap-4 sm:justify-start justify-center gap-4 850:justify-between items-center`}>
        <img src={game?.SummonerSpellOnGameDetail[0]?.summonerSpell?.img} alt="" className="500:h-[40px] h-[30px] w-[30px] 500:w-[40px]"/>
        <img src={game?.SummonerSpellOnGameDetail[1]?.summonerSpell?.img} alt="" className="500:h-[40px] h-[30px] w-[30px] 500:w-[40px]"/>
      </div>
      <div className={`flex flex-col h-full justify-between w-20 items-center`}>
        <Paragraph className="500:text-sm text-xs">{game?.date}</Paragraph>
        <p
          className={`font-boogaloo text-2xl 500:text-[30px] ${
            game?.result === true ? "text-blue-win" : game?.result === false ? "text-red-loss" : "text-gray-medium"
          }`}
        >
          {game?.result ? "Victoire" : "Défaite"}
        </p>
        <Paragraph className="text-sm 500:text-base">{game?.time}</Paragraph>
      </div>
    </div>
  );
}
