import * as React from "react";
import "./App.css";
import { useUserContext } from "./context";
import { AuthRouter } from "./routers/auth";
import { DefaultRouter } from "./routers/default";
import { LoadingRouter } from "./routers/loading";


export default function App() {

  const { status } = useUserContext();

  return (
    <React.Fragment>
      {status === "nothing" || status === null ? <AuthRouter /> : (status === "pending" ? <LoadingRouter/> : (status === "connected" && <DefaultRouter/>))}
    </React.Fragment>
  );
}
