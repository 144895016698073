import joinClasses from "../../../helpers/joinClasses";

export function LongButton({ children, onClick, className = "", type = "button" }) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={joinClasses(
        className,
        `bg-yellow  gradient-animation duration-200 w-full font-poppins font-medium py-2 px-7 rounded-lg text-gray-medium`
      )}
    >
      {children}
    </button>
  );
}
