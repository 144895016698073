import { useEffect, useState } from "react";
import { Title } from "../../../atoms";
import { GameCard } from "../../../molecule";

export function History({summoner, isOpen = false }) {


  const [games, setGames] = useState([
    {result: true},
    {result: false},
    {result: true},
    {result: false},
    {result: true},
    {result: false},
    {result: true},
    {result: false},
    {result: true},
    {result: false},
  ]);

  useEffect(() => {
    setGames(summoner?.Game);
  }, [summoner])

  return (
    <section
      className={`max-w-[1500px] 2xl:px-0 px-[5%] overflow-hidden ${
        isOpen && games?.length > 0 ? "850:h-[940px] h-[1508px] pt-10 pb-16" : isOpen && games?.length === 0 ? "py-10" : "h-0 pt-0 pb-0"
      } duration-200  w-full grid grid-cols-12 gap-x-6 lg:gap-x-10 850:gap-y-8 gap-y-4 `}
    >
      {games?.length > 0 ? games?.map((game, index) => (
        <GameCard key={index} isOpen={isOpen}  game={game?.GameDetail} />
      )) :  <Title className="col-span-12 text-center">Aucune partie trouvé, peut-être faut-il actualiser les données</Title>}
    </section>
  );
}
