import { useEffect, useState } from "react";
import { LongButton, NewAuthInput, Paragraph } from "../../atoms";
import { Card } from "../../molecule";
import top from "../../../assets/roles/top.svg";
import jungle from "../../../assets/roles/jungle.svg";
import mid from "../../../assets/roles/mid.svg";
import adc from "../../../assets/roles/adc.svg";
import support from "../../../assets/roles/support.svg";
import { updateSummonerAccount } from "../../../api/league/summoner/summoner";
import { toast } from "react-toastify";
import { useUserContext } from "../../../context";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";

export function LeagueCard() {

    const { user, setUser, setSmurf } = useUserContext();

    const [mainAccount, setMainAccount] = useState({});
    const [secondaryAccount, setSecondaryAccount] = useState({});

    const [refreshLoading, setRefreshLoading] = useState(false);

    const [mainAccountName, setMainAccountName] = useState(mainAccount.name);
    const [secondaryAccountName, setSecondaryAccountName] = useState(secondaryAccount.name);

    const [roleSelectionList, setRoleSelectionList] = useState([
        { name: "TOP", img: top, status: false },
        { name: "JUNGLE", img: jungle, status: false },
        { name: "MID", img: mid, status: false },
        { name: "ADC", img: adc, status: false },
        { name: "SUPPORT", img: support, status: false },
    ]);

    // Loads summoner roles
    const setRoleOnLoad = () => {
        if (mainAccount?.mainRole) {
            setRoleSelectionList(roleSelectionList.map((role) => {
                if (role.name === mainAccount.mainRole) {
                    role.status = "main";
                }
                return role;
            }
            ));
        }
        if (mainAccount?.secondaryRole) {
            setRoleSelectionList(roleSelectionList.map((role) => {
                if (role.name === mainAccount.secondaryRole) {
                    role.status = "secondary";
                }
                return role;
            }
            ));
        }
    }

    const handleRoleSelection = (item) => {
        // if every roleSelectionList.status is false, set status to "main", else one is "main" and all other to false and item status is false, set status to "secondary"
        if (roleSelectionList.every((role) => role.status === false)) {
            setRoleSelectionList(roleSelectionList.map((role) => role.name === item.name ? { ...role, status: "main" } : role))
        } else if (
            // search if one roleSelectionList.status is "main" and all other to false
            roleSelectionList.some((role) => role.status === "main") &&
            roleSelectionList.every((role) => role.status === "main" || role.status === false)
        ) {
            // set the item status to "secondary" if it's not already "main"
            if (item.status !== "main") {
                setRoleSelectionList(roleSelectionList.map((role) => role.name === item.name ? { ...role, status: "secondary" } : role))
            } else if (
                // item.status === main, set item.status to false
                item.status === "main"
            ) {
                setRoleSelectionList(roleSelectionList.map((role) => role.name === item.name ? { ...role, status: false } : role))
            }
        } else if (
            // search if one roleSelectionList.status is "secondary" and all other to false or "main"
            roleSelectionList.some((role) => role.status === "secondary") &&
            roleSelectionList.every((role) => role.status === "secondary" || role.status === false || role.status === "main")
        ) {
            // if current item.status is "secondary", set it to false
            if (item.status === "secondary") {
                setRoleSelectionList(roleSelectionList.map((role) => role.name === item.name ? { ...role, status: false } : role))
            }
            // if current item.status is "main", set it to "main"
            
            // if current item.status is false, set it to "secondary" and set all "secondary" to false
            if (item.status === false) {
                setRoleSelectionList(roleSelectionList.map((role) => role.name === item.name ? { ...role, status: "secondary" } : role.status === "secondary" ? { ...role, status: false } : role))
            }
        }
    }

    useEffect(() => {
        filterAccounts(user);
    }, [user])

    useEffect(() => {
        setRoleOnLoad();
    }, [mainAccount])

    const filterAccounts = (user) => {
        // find summoner with type main
        const main = user?.Summoner?.find((account) => account.type === "main");
        if (main) {
            setMainAccount(main);
        }
        setMainAccountName(main?.name);

        const secondary = user?.Summoner?.find((account) => account.type === "secondary");
        if (secondary) {
            setSecondaryAccount(secondary);
        }
        setSecondaryAccountName(secondary?.name);
    }

    const submitLeague = async (e) => {
        e.preventDefault();
        setRefreshLoading(true);
        const token = localStorage.getItem("bifam-token");
        const roles = roleSelectionList.filter((role) => role.status === "main" || role.status === "secondary");
        if(mainAccountName !== mainAccount?.name || secondaryAccountName !== secondaryAccount?.name || roles.length >= 0) {
            const response = await updateSummonerAccount({
                summonerName: mainAccountName, smurf: secondaryAccountName, token:
                token, roles: roles
            }); 
            setUser(response.data)
            if(response.data.Summoner.length < 2) {
                setSmurf(null)
            }
            if(response.error === false){
                toast.success("Vos informations League Of Legends ont bien été mises à jour ");
            }
        }
        setRefreshLoading(false);
    }


    return (
        <Card className=" !h-auto mx-0 !w-[60%]" title="League of Legends">
            <div className="flex flex-row justify-between h-full items-start w-full">
                <form onSubmit={submitLeague} className="w-[72.5%] flex flex-col justify-between self-stretch h-auto">
                    <NewAuthInput onChange={(e) => { setMainAccountName(e.target.value) }} defaultValue={mainAccount?.name} placeholder="Nom d'invocateur" className=" w-full" />
                    <NewAuthInput onBlur={(e) => { setSecondaryAccountName(e.target.value) }} onChange={(e) => { setSecondaryAccountName(e.target.value) }} defaultValue={secondaryAccount?.name} placeholder="Nom d'invocateur secondaire" className=" w-full " />
                    <div className="w-full flex items-center justify-between gap-5">
                        <div className="flex items-center w-full gap-5">
                            <Paragraph className="text-gray-medium text-sm">Principal </Paragraph>
                            <div className="w-1/2 h-[3px] bg-yellow rounded-full">
                            </div>
                        </div>
                        <div className="h-full w-[1px] border border-gray-medium"></div>
                        <div className="flex items-center w-full gap-5">
                            <Paragraph className="text-gray-medium text-sm ">Secondaire </Paragraph>
                            <div className="w-1/2 h-[3px] bg-gray-medium rounded-full">
                            </div>
                        </div>
                    </div>
                    <LongButton type="submit" className={`${refreshLoading ? "bg-white border-yellow border-2 flex items-center gap-2 justify-center py-0 h-10" : ""}`}>{refreshLoading ? <><Paragraph>Chargement</Paragraph> <Lottie className="h-14 overflow-hidden" animationData={loadingAnimation} /></> : "Enregistrer"}</LongButton>

                </form>
                <div className="flex flex-col gap-3">
                    {roleSelectionList?.map((role, index) => (
                        <div key={index}
                            onClick={() => handleRoleSelection(role)}
                            className={`${role.status === "main" ? "!bg-yellow" : (role.status === "secondary" && "!bg-gray-medium ")} shadow bg-white rounded-lg gap-1.5 flex items-center py-1.5 px-2 cursor-pointer group
                            // if all oleSelectionList.status are false 
                            ${roleSelectionList.every((role) => role.status === false) && "hover:bg-yellow"}
                            ${roleSelectionList.some((role) => role.status === "main") && roleSelectionList.every((role) => role.status === "main" || role.status === false) && role.status === false && "hover:bg-gray-medium"}
                            `}>
                            <img src={role.img} alt={role.name} />
                            <Paragraph className={`text-sm ${role.status === "secondary" && "text-white"}       
                                ${roleSelectionList.some((role) => role.status === "main") && roleSelectionList.every((role) => role.status === "main" || role.status === false) && role.status === false && "group-hover:text-white"}
                            `}>{role.name}</Paragraph>
                        </div>
                    ))}
                </div>
            </div>
        </Card>
    )
}