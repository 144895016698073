import joinClasses from "../../../helpers/joinClasses";

export function Card({ className = "", title, children, classNameTitle = "",  }) {
  return (
    <div
      className={joinClasses(
        className,
        `rounded-lg shadow 380:w-[352px] 380:px-[30px] relative bg-gray  h-[502px]  pt-8 m-auto pb-7  bg-grey flex flex-col `
      )}
    >
      <h1 className={` ${classNameTitle} font-boogaloo text-[26px] uppercase mb-8 leading-7 text-center text-gray-medium`}>
        {title}
      </h1>
      {children}
    </div>
  );
}
