import Bandeau from "../components/atoms/Bandeau";
import Button from "../components/atoms/buttons/Button";
import { AuthInput, SmallText, TeamRadio } from "../components/atoms";
import { Card } from "../components/molecule";
import { useEffect, useState } from "react";
import Template from "../components/molecule/Template";
import "../components/atoms/inputs/switch.css";
import { registerUser } from "../api/auth/auth";
import { getAllTeams } from "../api/teams/teams";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export default function Register() {
  const [teams, setTeams] = useState([]);
  const [teamState, setTeamState] = useState([]);
  const [smurf, setSmurf] = useState(false);
  const [step, setStep] = useState(false);

  const navigate = useNavigate()

  const [registration, setRegistration] = useState({
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      conditions: true,
    },
    summoner: {
      summonerName: "",
      teams: [],
      smurf: "",
    },
  });

  const handleOnChange = (team, position) => {
    const updatedCheckedState = teamState.map((item, index) =>
      index === position && item === false
        ? team.id
        : index === position && item === team.id
        ? false
        : item
    );
    setTeamState(updatedCheckedState);
    setRegistration({
      ...registration,
      summoner: { ...registration.summoner, teams: updatedCheckedState },
    });
  };

  const register = async () => {
    const response = await registerUser(registration);
    if(response.error === false){
      toast.success(response.message)
      navigate("/login")
    }else{
      toast.error(response.message)
    }
  };

  const getTeams = async () => {
    const teams = await getAllTeams();
    setTeams(teams.data);
    setTeamState(new Array(teams.data.length).fill(false));
  };
  useEffect(() => {
    getTeams();
  }, []);

  return (
    <Template className="flex items-center justify-center h-full">
      <Bandeau className="container" />
      <div className="h-full w-full flex min-h-[600px] items-center justify-center">
        <div className={`w-full ${step && "md:flex hidden" } items-center justify-center `}>
          <Card className="w-[90%] px-3" title={"INFORMATIONS DE CONNEXION"}>
            {step && (
              <div className="w-full h-full absolute bg-gray-medium top-0 left-0 rounded-lg opacity-40"></div>
            )}
            <div className="flex flex-col items-center justify-between flex flex-col h-full ">
              <div className="flex flex-col items-center  w-full  gap-5">
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      account: {
                        ...registration.account,
                        firstName: e.target.value,
                      },
                    });
                  }}
                  placeholder="Prénom"
                  type="text"
                />
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      account: {
                        ...registration.account,
                        lastName: e.target.value,
                      },
                    });
                  }}
                  placeholder="Nom"
                  type="text"
                />
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      account: {
                        ...registration.account,
                        email: e.target.value,
                      },
                    });
                  }}
                  placeholder="Adresse email"
                  type="email"
                />
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      account: {
                        ...registration.account,
                        password: e.target.value,
                      },
                    });
                  }}
                  placeholder="Mot de passe"
                  type="password"
                />
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      account: {
                        ...registration.account,
                        confirmPassword: e.target.value,
                      },
                    });
                  }}
                  placeholder="Confirmer mot de passe"
                  type="password"
                />
              </div>
              <div className="w-full flex justify-end">
                <Button onClick={() => setStep(true)}>Suivant</Button>
              </div>
            </div>
          </Card>
        </div>
        <div className={`w-full flex items-center ${!step && "md:flex hidden" } justify-center`}>
          <Card className={`w-[90%] px-3`} title={`COMPTE LEAGUE OF LEGENDS`}>
            {!step && (
              <div className="w-full h-full absolute bg-gray-medium top-0 left-0 z-10 rounded-lg opacity-40"></div>
            )}
            <div className="flex flex-col items-center w-full justify-between flex flex-col h-full">
              <div className="flex flex-col items-center w-full gap-3">
                <AuthInput
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      summoner: {
                        ...registration.summoner,
                        summonerName: e.target.value,
                      },
                    });
                  }}
                  placeholder="Nom d'invocateur"
                  type="text"
                />
                <div className="w-full">
                <SmallText>
                  De quelle(s) équipe(s) faites-vous partie ?
                </SmallText>
                </div>
                <div className="flex flex-col items-start w-full gap-2.5">
                  {teams.map((team, index) => {
                    return (
                      <TeamRadio
                        key={index}
                        name={team.name}
                        checked={teamState[index]}
                        onChange={() => handleOnChange(team, index)}
                      />
                    );
                  })}
                </div>
                <div className="flex items-center w-full justify-between">
                  <SmallText
                    className="form-check-label inline-block text-gray-800"
                    for="flexSwitchCheckDefault"
                  >
                    Avez-vous un compte secondaire ?
                  </SmallText>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setSmurf(e.target.checked);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="w-full relative">
                  {!smurf && (
                    <div className="w-full h-full absolute bg-gray-medium top-0 rounded-lg opacity-40"></div>
                  )}
                  <AuthInput
                    onChange={(e) => {
                      setRegistration({
                        ...registration,
                        summoner: {
                          ...registration.summoner,
                          smurf: e.target.value,
                        },
                      });
                    }}
                    placeholder="Nom d'invocateur (secondaire)"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="w-full gap-5 flex w-full m-auto justify-between">
              <Button className="!px-2.5" onClick={() => setStep(false)}>
              <svg className="fill-gray-medium" width="24" height="24" viewBox="0 0 24 24"><path d="m10.875 19.3l-6.6-6.6q-.15-.15-.213-.325Q4 12.2 4 12t.062-.375q.063-.175.213-.325l6.6-6.6q.275-.275.687-.288q.413-.012.713.288q.3.275.313.687q.012.413-.288.713L7.4 11h11.175q.425 0 .713.287q.287.288.287.713t-.287.712Q19 13 18.575 13H7.4l4.9 4.9q.275.275.288.7q.012.425-.288.7q-.275.3-.7.3q-.425 0-.725-.3Z"/></svg>
              </Button>
              <Button className="w-full"
                onClick={() => {
                  register();
                }}
              >
                S'inscrire
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </Template>
  );
}
