export function Gellule({ index, item,children, state, setState = () => {}, list, setList = () => {} }) {

    const removeGellule = () => {
        setState(state.filter((item2, index2) => index2 !== index))
        // add to list if not already in it
        if (!list.includes(item)) {
            setList([...list, item])
        }
    }

    return (
        <div
            onClick={() => removeGellule()}
            className="cursor-pointer flex items-center bg-yellow rounded-full py-1 pr-2.5 pl-1.5 gap-2">
            <svg width="16" height="16" viewBox="0 0 15 15"><path fill="currentColor" fillRule="evenodd" d="M.877 7.5a6.623 6.623 0 1 1 13.246 0a6.623 6.623 0 0 1-13.246 0ZM7.5 1.827a5.673 5.673 0 1 0 0 11.346a5.673 5.673 0 0 0 0-11.346Zm2.354 3.32a.5.5 0 0 1 0 .707L8.207 7.5l1.647 1.646a.5.5 0 0 1-.708.708L7.5 8.207L5.854 9.854a.5.5 0 0 1-.708-.708L6.793 7.5L5.146 5.854a.5.5 0 0 1 .708-.708L7.5 6.793l1.646-1.647a.5.5 0 0 1 .708 0Z" clipRule="evenodd" /></svg>
            <p className="text-sm font-poppins">{children}</p>
        </div>
    )
}