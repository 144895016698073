import { useEffect, useState } from "react";
import { SmallText } from "../../atoms";
import unranked from "../../../assets/icons/ranks/unranked.svg";
import iron from "../../../assets/icons/ranks/iron.svg";
import bronze from "../../../assets/icons/ranks/iron.svg";
import silver from "../../../assets/icons/ranks/silver.svg";
import gold from "../../../assets/icons/ranks/gold.svg";
import platinum from "../../../assets/icons/ranks/platinum.svg";
import diamond from "../../../assets/icons/ranks/diamond.svg";
import master from "../../../assets/icons/ranks/master.svg";
import grandmaster from "../../../assets/icons/ranks/grandmaster.svg";
import challenger from "../../../assets/icons/ranks/challenger.svg";
import { useUserContext } from "../../../context";

export function HistoCard({ summoner, index, games }) {
  const [soloQ, setSoloQ] = useState(null);
  const [flexQ, setFlexQ] = useState(null);
  const [mostPlayerChamp, setMostPlayerChamp] = useState(null);

  const { getTierColor = () => {}, getRank = () => {} } = useUserContext();

  const winRate = (wins, losses) => {
    return ((wins / (wins + losses)) * 100).toFixed(0);
  };

  const totalGames = (wins, losses) => {
    return wins + losses;
  };

  const handleRanks = () => {
    // find in summoner?.Rank the rank with queueType = RANKED_SOLO_5x5
    // find in summoner?.Rank the rank with queueType = RANKED_FLEX_SR
    setSoloQ(summoner?.Rank?.find((rank) => rank.queue === "RANKED_SOLO_5x5"));
    setFlexQ(summoner?.Rank?.find((rank) => rank.queue === "RANKED_FLEX_SR"));
  };

  const getTierImg = (queue) => {
    switch (queue?.tier) {
      case "IRON":
        return iron;
      case "BRONZE":
        return bronze;
      case "SILVER":
        return silver;
      case "GOLD":
        return gold;
      case "PLATINUM":
        return platinum;
      case "DIAMOND":
        return diamond;
      case "MASTER":
        return master;
      case "GRANDMASTER":
        return grandmaster;
      case "CHALLENGER":
        return challenger;
      default:
        return unranked;
    }
  };

  const getMostChampions = (summoner) => {
    const account = summoner?.Game;
    let champions = [];
    account?.forEach((game) => {
      champions.push(game?.GameDetail?.champion?.name);
    });

    const counts = {};
    champions.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });
    const max = Math.max(...Object.values(counts));
    const mostPlayed = Object.keys(counts).filter((x) => counts[x] === max);
    setMostPlayerChamp(mostPlayed[0].replace(/ /g, "").replace(/'/g, ""));
  };

  useEffect(() => {
    handleRanks();
    getMostChampions(summoner);
  }, [summoner]);

  return (
    <div className="group w-[45%] h-[276px] ring-[3px] rounded-lg ring-gray-medium cursor-pointer">
      <a
        href={`/league/summoner?name=${summoner.name}`}
        className="w-full h-full rounded-lg flex group-odd:flex-row group-even:flex-row-reverse "
      >
        <img
          src={`http://ddragon.leagueoflegends.com/cdn/img/champion/loading/${mostPlayerChamp}_0.jpg`}
          alt={mostPlayerChamp}
          className={`h-full w-[22.5%]  object-cover border-none group-odd:rounded-tl-lg group-odd:rounded-bl-lg group-even:rounded-tr-lg group-even:rounded-br-lg`}
        />
        <div
          className={`h-full w-[77.5%] group-odd:rounded-tr-lg group-odd:rounded-br-lg group-even:rounded-tl-lg group-even:rounded-bl-lg`}
        >
          <div
            className={`w-full  h-[45px] bg-yellow group-odd:rounded-tr-lg group-even:rounded-tl-lg`}
          >
            <h3 className="font-boogaloo text-[30px] text-gray-medium text-center">
              {summoner.name}
            </h3>
          </div>
          <div className="w-full bg-gray border-y border-gray-medium h-[55px] px-5 flex flex-col justify-evenly">
            <div className="flex items-center w-full justify-between">
              <SmallText className="w-[56px]">Solo/Duo</SmallText>
              <span className="w-2.5 h-[1.5px] bg-gray-medium"></span>
              <div className="flex items-center gap-2.5  w-[115px]">
                <img
                  className="h-5"
                  src={getTierImg(soloQ)}
                  alt={soloQ?.tier}
                />
                <SmallText
                  className={` font-poppins text-xs ${getTierColor(soloQ)}`}
                >
                  {getRank(soloQ)}
                </SmallText>
              </div>
              <SmallText className="!font-bold w-[34px] text-center">
                {Number.isInteger(soloQ?.leaguePoints) &&
                  soloQ?.leaguePoints + "LP"}
              </SmallText>
              <SmallText className="!font-bold w-[31px]">
                {soloQ && "50%"}
              </SmallText>
              <SmallText className="w-[43px] text-end">
                {soloQ && "(" + totalGames(soloQ?.wins, soloQ?.losses) + ")"}
              </SmallText>
            </div>
            <div className="flex items-center w-full justify-between">
              <SmallText className="w-[56px]">Flex</SmallText>
              <span className="w-2.5 h-[1.5px] bg-gray-medium"></span>
              <div className="flex items-center gap-2.5 w-[115px]">
                <img
                  className="h-5"
                  src={getTierImg(flexQ)}
                  alt={flexQ?.tier}
                />
                <SmallText
                  className={` font-poppins text-xs ${getTierColor(flexQ)}`}
                >
                  {getRank(flexQ)}
                </SmallText>
              </div>

              <SmallText className="!font-bold w-[34px] text-center">
                {Number.isInteger(flexQ?.leaguePoints) &&
                  flexQ?.leaguePoints + "LP"}
              </SmallText>
              <SmallText className="!font-bold w-[31px]">
                {flexQ && "50%"}
              </SmallText>
              <SmallText className="w-[43px] text-end">
                {flexQ && "(" + totalGames(flexQ?.wins, flexQ?.losses) + ")"}
              </SmallText>
            </div>
          </div>
          <div
            className="w-full rounded-br-lg pt-2"
            style={{ height: "calc(100% - 45px - 55px)" }}
          >
            <p className="font-poppins text-center  italic">
              10 dernières games
            </p>
            <div
              className="w-full justify-between flex items-center flex-wrap px-7 "
              style={{ height: "calc(100% - 32px)" }}
            >
              {summoner?.Game?.map((game, index) => {
                return (
                  <div
                    key={index}
                    className="w-1/5 flex justify-center h-[40px]"
                  >
                    <div
                      className={`h-full w-[40px] border-[2px] rounded-lg bg-gray text-lg flex justify-center items-center ${
                        game?.GameDetail?.result
                          ? "border-blue-win"
                          : "border-red-loss"
                      }  `}
                    >
                      <p
                        className={`font-boogaloo ${
                          game?.GameDetail?.result
                            ? "text-blue-win"
                            : "text-red-loss"
                        } `}
                      >
                        {game?.GameDetail?.result ? "V" : "D"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
