import axios from 'axios';

export async function getChampionType(){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/championtype`
    );
        return response.data;
}

export async function updateClashCard(payload){
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/clash/card/update`
        , payload
    );
        return response.data;
}

export async function getChampionTypeForASummoner(id){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/championtype/${id}`
    );
        return response.data;
}