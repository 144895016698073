import joinClasses from "../../../helpers/joinClasses";
import { Paragraph } from "../texts/Paragraph";

export function NewAuthInput({
    placeholder,
    type = "text",
    onChange,
    onBlur,
    className = "",
    defaultValue = "",
    classNameContainer = "",
    children,
    onClick = () => { },
    isOpen = false,
    setIsOpen = () => { },
}) {

    if (type === "text" || type === "email" || type === "password") {

        return (
            <div className={`flex flex-col gap-1 w-full ${classNameContainer}`}>
                <label className="font-poppins text-gray-medium text-sm pl-3">{placeholder}</label>
                <input
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    type={type}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={joinClasses(
                        className,
                        `w-full bg-white border-yellow active:border-yellow outline-none border-2 rounded-lg font-medium font-poppins text-sm pl-3 h-[38px] text-gray-medium placeholder-gray-medium/[0.3]`
                    )}
                />
            </div>
        );
    } else if (type === "select") {
        return (
            <div className={`flex flex-col gap-1 w-full ${classNameContainer}`}>
                <div className="flex items-center justify-between pl-3">
                    <label className="font-poppins text-gray-medium text-sm ">{placeholder}</label>
                    {isOpen && <Paragraph className="text-sm underline cursor-pointer" onClick={() => { setIsOpen(false) }}>fermer</Paragraph>}
                </div>
                <div className="relative">
                    <input
                        onClick={onClick}
                        placeholder={placeholder}
                        type={type}
                        onChange={onChange}
                        className={joinClasses(
                            className,
                            `w-full bg-white border-yellow active:border-yellow outline-none border-2 rounded-lg font-medium  font-poppins text-sm pl-3 h-[38px] text-gray-medium placeholder-gray-medium/[0.3]`
                        )}
                    />
                    {children}
                </div>
            </div>
        )
    }
}
