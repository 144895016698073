import unranked from "../../../../assets/icons/ranks/unranked.svg";
import iron from "../../../../assets/icons/ranks/iron.svg";
import bronze from "../../../../assets/icons/ranks/iron.svg";
import silver from "../../../../assets/icons/ranks/silver.svg";
import gold from "../../../../assets/icons/ranks/gold.svg";
import platinum from "../../../../assets/icons/ranks/platinum.svg";
import diamond from "../../../../assets/icons/ranks/diamond.svg";
import master from "../../../../assets/icons/ranks/master.svg";
import grandmaster from "../../../../assets/icons/ranks/grandmaster.svg";
import challenger from "../../../../assets/icons/ranks/challenger.svg";
import {
  BoldParagraph,
  MediumParagraph,
  Paragraph,
  Title,
} from "../../../atoms";
import { RankCard } from "../../../molecule";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../context";

export function Ranked({ isOpen = false }) {
  const { summoner, getTierColor = () => {},getRank = () => {} } = useUserContext();

  const [soloQ, setSoloQ] = useState(null);
  const [flexQ, setFlexQ] = useState(null);

  const winRate = (wins, losses) => {
    return ((wins / (wins + losses)) * 100).toFixed(0);
  };

  const getTierImg = (queue) => {
    switch (queue?.tier) {
      case "IRON":
        return iron;
      case "BRONZE":
        return bronze;
      case "SILVER":
        return silver;
      case "GOLD":
        return gold;
      case "PLATINUM":
        return platinum;
      case "DIAMOND":
        return diamond;
      case "MASTER":
        return master;
      case "GRANDMASTER":
        return grandmaster;
      case "CHALLENGER":
        return challenger;
      default:
        return unranked;
    }
  };

  const handleRanks = () => {
    setSoloQ(summoner?.Rank?.find((rank) => rank.queue === "RANKED_SOLO_5x5"));
    setFlexQ(summoner?.Rank?.find((rank) => rank.queue === "RANKED_FLEX_SR"));
  };

  useEffect(() => {
    handleRanks();
  }, [summoner]);

  return (
    <section
      className={`max-w-[1500px] ${
        isOpen ? "h-[443px] py-16" : "h-0 py-0"
      } duration-200 grid grid-cols-11 gap-4  w-full overflow-hidden`}
    >
      <RankCard className="col-start-3">
        <img
          className="w-[100px] h-[82px]"
          src={getTierImg(soloQ)}
          alt={soloQ?.tier}
        />
        <MediumParagraph className="mt-1">Classé Solo/Duo</MediumParagraph>
        <Title className={`uppercase ${getTierColor(soloQ)}`}>
          {getRank(soloQ)}
        </Title>
        {soloQ?.tier && (
          <>
            <BoldParagraph>{soloQ?.leaguePoints} LP</BoldParagraph>
            <Paragraph>
              <strong>{soloQ?.wins}</strong> Victoires{" "}
              <strong>{soloQ?.losses}</strong> Défaites
            </Paragraph>
            <Paragraph>
              Win rate <strong>{winRate(soloQ?.wins, soloQ?.losses)}%</strong>
            </Paragraph>
            <Paragraph className="mt-1">{soloQ?.leagueName}</Paragraph>
          </>
        )}
      </RankCard>
      <RankCard className="col-start-7">
        <img
          className="w-[100px] h-[82px]"
          src={getTierImg(flexQ)}
          alt={flexQ?.tier}
        />
        <MediumParagraph className="mt-1">Classé Flexible</MediumParagraph>
        <Title className={`uppercase ${getTierColor(flexQ)}`}>
          {getRank(flexQ)}
        </Title>
        {flexQ?.tier && (
          <>
            <BoldParagraph>{flexQ?.leaguePoints} LP</BoldParagraph>
            <Paragraph>
              <strong>{flexQ?.wins}</strong> Victoires{" "}
              <strong>{flexQ?.losses}</strong> Défaites
            </Paragraph>
            <Paragraph>
              Win rate <strong>{winRate(flexQ?.wins, flexQ?.losses)}%</strong>
            </Paragraph>
            <Paragraph className="mt-1">{flexQ?.leagueName}</Paragraph>
          </>
        )}
      </RankCard>
    </section>
  );
}
