import Template from "../../../components/molecule/Template";
import vayne from "../../../assets/img/vayne.svg";
import { Paragraph, LongButton } from "../../../components/atoms";
import { useEffect, useState } from "react";
import {
  SummonerBandeauInfo,
  SummonerTemplate,
} from "../../../components/molecule";
import { Clash, History, Ranked } from "../../../components/organisms";
import { useSearchParams } from "react-router-dom";
import { getSummonerByName } from "../../../api/league/summoner/summoner";
import { getMasteries } from "../../../api/league/masteries/masteries";
import { useUserContext } from "../../../context";
import { refreshUser } from "../../../api/league/summoner/refresh";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";



export default function Summoner() {

  const { summoner, setSummoner, smurf, masteries, getSummoner, total } = useUserContext();

  const [refreshLoading, setRefreshLoading] = useState(false);

  const [historiqueOpen, setHistoriqueOpen] = useState(false);
  const [rankedOpen, setRankedOpen] = useState(false);
  const [clashOpen, setClashOpen] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const querySummoner = searchParams.get("name");

  const refresh = async () => {
    setRefreshLoading(true);
    const response = await refreshUser(summoner.name);
    if (response.error === false) {
      setSummoner(response.data);
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
    setRefreshLoading(false);
  };

  useEffect(() => {
    getSummoner(querySummoner);
    handleLocalStorage();
  }, []);


  const handleLocalStorage = () => {
    if (!localStorage.getItem("SummonerProfile")) {
      localStorage.setItem(
        "SummonerProfile",
        JSON.stringify([{ history: false, ranked: false, clash: false }])
      );
    } else {
      const SummonerProfile = JSON.parse(
        localStorage.getItem("SummonerProfile")
      );
      if (SummonerProfile[0].history) {
        setHistoriqueOpen(true);
      }
      if (SummonerProfile[0].ranked) {
        setRankedOpen(true);
      }
      if (SummonerProfile[0].clash) {
        setClashOpen(true);
      }
    }
  };

  const changeBandeauxState = (bandeau, state) => {
    const SummonerProfile = JSON.parse(localStorage.getItem("SummonerProfile"));
    if (bandeau === "history") {
      setHistoriqueOpen(state);
      SummonerProfile[0].history = state;
    }
    if (bandeau === "ranked") {
      setRankedOpen(state);
      SummonerProfile[0].ranked = state;
    }
    if (bandeau === "clash") {
      setClashOpen(state);
      SummonerProfile[0].clash = state;
    }
    localStorage.setItem("SummonerProfile", JSON.stringify(SummonerProfile));
  };

  return (
    <SummonerTemplate searchedSummoner={summoner} >
      <div className="w-full flex flex-col items-center justify-center">
        <div className="max-w-[1500px] 2xl:px-0 px-[5%] w-full flex flex-col gap-10  xl:grid grid-cols-12 xl:gap-20 py-16">
          <div className="xl:col-span-9 xl:grid flex sm:flex-nowrap flex-wrap w-full justify-center sm:justify-between grid-cols-10 lg:gap-10 md:gap-8 gap-4 xl:gap-14 2xl:gap-20">
            {masteries?.map((mastery, index) => (
              <div key={index} className="col-span-2 h-1/2 xl:h-full flex flex-col w-1/4 sm:w-full items-center gap-2.5">
                <img className="w-full w-full h-full object-cover object-top" src={mastery?.img} alt={mastery?.champion} />
                <Paragraph className="font-medium text-center">{mastery?.championPointsFormated} pts</Paragraph>
              </div>
            ))}
          </div>
          <div className=" col-start-10 col-span-3 flex xl:flex-col sm:flex-row flex-col sm:flex-row-reverse items-center justify-between xl:justify-center gap-8">
            <LongButton className={`xl:w-full sm:w-1/2  ${refreshLoading ? "bg-white border-yellow border-2 flex items-center gap-2 justify-center py-0 h-10" : ""}`} onClick={() => { refresh() }}>{refreshLoading ? <><Paragraph>Chargement</Paragraph> <Lottie className="h-14 overflow-hidden" animationData={loadingAnimation} /></> : "Actualiser les données"}</LongButton>
            <div className="flex xl:flex-col gap-8 sm:flex-row flex-col">
            <Paragraph className="font-medium">Total : {total} pts</Paragraph>
            <a
              className="font-popping font-medium underline text-center text-gray-medium min-w-max"
              href={`https://masteries.biscottefamily.fr/search?summoner=${summoner?.name}`}
              >
              Voir plus
            </a>
              </div>
          </div>
        </div>
        <SummonerBandeauInfo
          state={historiqueOpen}
          setState={changeBandeauxState}
          title="HISTORIQUE"
          name="history"
        />
        <History summoner={summoner} isOpen={historiqueOpen} />
        <SummonerBandeauInfo
          state={rankedOpen}
          setState={changeBandeauxState}
          title="CLASSEMENTS"
          name="ranked"
        />
        <Ranked isOpen={rankedOpen} />
        <SummonerBandeauInfo
          state={clashOpen}
          setState={changeBandeauxState}
          title="CLASH"
          name="clash"
        />
        <Clash summoner={summoner} isOpen={clashOpen} />
      </div>
    </SummonerTemplate>
  );
}
