import { useEffect, useState } from "react";
import { MediumParagraph, Paragraph, Title } from "../../../atoms";
import uthred from "../../../../assets/img/uthred.png";
import { useUserContext } from "../../../../context";
import { updateClashDispo } from "../../../../api/league/clash/clashDisponibility";

export function Clash({ summoner, isOpen = false }) {

  const [clashs, setClashs] = useState([]);
  const [types, setTypes] = useState([]);

  const { setSummoner, user } = useUserContext();


  const handleStatus = async (item) => {
    // add + 1 to status, if status is 2, set status to 0
    if (item.status === 2) {
      item.status = 0;
    } else {
      item.status = item.status + 1;
    }
    const token = localStorage.getItem("bifam-token");
    const response = await updateClashDispo({ clashDisponibility: item, token: token })
    setSummoner(response.data);
  };



  useEffect(() => {
    setClashs(summoner?.ClashDisponibility);
    setTypes(summoner?.ChampionType);

  }, [summoner]);

  const formatDate = (date) => {
    const data = date.split("T")[0];
    const day = data.split("-")[2];
    const month = data.split("-")[1];
    const year = data.split("-")[0];
    return `${day}/${month}/${year}`;

  };



  return (
    <section className={`max-w-[1500px] mb-20 ${!isOpen && "h-0"} duration-200 overflow-hidden w-full`}>

      {summoner?.clashCard?.description && summoner?.ChampionType?.length > 0 ?
        <>
          <h2 className="font-boogaloo text-[42px] text-gray-medium text-center mt-16 mb-12">Profil de clash</h2>
          <div className="w-full max-h-[330px] grid grid-cols-12 gap-[50px]">
            <div className="col-span-6 col-start-4 bg-gray border-[3px] border-yellow rounded-lg flex flex-col gap-2">
              <div className="w-full flex gap-5">
                <img src={uthred} className="rounded-full aspect-square object-cover h-40 pl-2 pt-2" alt="" />
                <div className="flex py-2 h-40 gap-3">
                  <div className="flex flex-col justify-center h-full">
                    {types?.length > 0 && <div className="flex items-center h-1/2">
                      <MediumParagraph>Types de champions :</MediumParagraph>
                    </div>}
                    {summoner?.secondaryRole && <div className="flex items-center h-1/2">
                      <MediumParagraph>Role secondaire :</MediumParagraph>
                    </div>}
                  </div>
                  <div className="flex flex-col justify-center h-full">
                    {types?.length > 0 && <div className="flex items-center flex-wrap gap-x-3  h-1/2">
                      {types.map((type, index) => (
                        <div key={index}
                          className="bg-yellow rounded-full py-1 px-3">
                          <p className="text-sm font-poppins">{type?.ChampionType?.name}</p>
                        </div>
                      ))}
                    </div>}
                    {summoner?.secondaryRole && <div className="flex items-center flex-wrap gap-x-3  h-1/2">
                      <div
                        className="bg-yellow rounded-full py-1 px-3">
                        <p className="text-sm font-poppins">{summoner?.secondaryRole}</p>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="h-full w-full max-h-[144px] flex items-center overflow-hidden ">
                <div className="w-full h-full  px-4 pb-1.5 overflow-auto">
                  {summoner?.clashCard?.description && <Paragraph className="pt-1 overflow-auto">{summoner?.clashCard?.description}</Paragraph>}
                </div>
              </div>
            </div>
          </div>
        </> : <Title className="text-center mt-16">Cet invocateur n'a pas encore renseigné son profil de clash</Title>}
      {clashs?.length > 0 ? <>
        <h2 className="font-boogaloo text-[42px] text-gray-medium text-center mt-16 mb-12">Disponibilités pour les prochains clashs ?</h2>
        <div className="w-full grid grid-cols-12 gap-[50px] gap-y-10 mb-20 ">
          {user?.id === summoner?.userId ? clashs?.map((clash, index) => (
            <div key={index} onClick={() => { handleStatus(clash) }} className="col-span-3 self-center">
              <div className={`py-4 border-[3px] cursor-pointer  ${clash.status === 0 ? "bg-gray border-yellow w-full" : (clash.status === 2 ? "bg-gray-medium border-gray-medium w-4/5" : (clash.status === 1 && "w-full bg-yellow border-gray-medium shadow"))} m-auto flex flex-col items-center justify-center gap-4 rounded-lg self-start`}>
                <h3 className={`font-medium ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>Coupe {clash?.Clash?.nameKey} | Jour {clash?.Clash?.nameKeySecondary}</h3>
                <Paragraph className={`font-medium ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>{clash?.Clash?.nameKeySecondary % 2 ? "Samedi" : "Dimanche"} {" "} {formatDate(clash?.Clash?.startTime)}</Paragraph>
                <Paragraph className={`font-medium uppercase underline tracking-wider ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>{clash.status === 0 ? "En attente" : (clash.status === 2 ? "Absent" : (clash.status === 1 && "Disponible"))}</Paragraph>
              </div>
            </div>
          )) : clashs?.map((clash, index) => (
            <div key={index} className="col-span-3 self-center">
              <div className={`py-4 border-[3px] ${clash.status === 0 ? "bg-gray border-yellow w-full" : (clash.status === 2 ? "bg-gray-medium border-gray-medium w-4/5" : (clash.status === 1 && "w-full bg-yellow border-gray-medium shadow"))} m-auto flex flex-col items-center justify-center gap-4 rounded-lg self-start`}>
                <h3 className={`font-medium ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>Coupe {clash?.Clash?.nameKey} | Jour {clash?.Clash?.nameKeySecondary}</h3>
                <Paragraph className={`font-medium ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>{clash?.Clash?.nameKeySecondary % 2 ? "Samedi" : "Dimanche"} {" "} {formatDate(clash?.Clash?.startTime)}</Paragraph>
                <Paragraph className={`font-medium uppercase underline tracking-wider ${clash.status === 0 ? "text-gray-medium" : (clash.status === 2 ? "text-xs text-white" : "text-white")}`}>{clash.status === 0 ? "En attente" : (clash.status === 2 ? "Absent" : (clash.status === 1 && "Disponible"))}</Paragraph>
              </div>
            </div>
          ))}
        </div>
      </> : <Title className="text-center mt-16">Il n'y a pas de clash prévu pour le moment</Title>
      }
    </section>
  );
}
