import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import "./style.css";
import { Title } from "../../atoms";
import { useUserContext } from "../../../context";

export function TeamHeader() {

  const { user } = useUserContext();

  const [summonerName, setSummonerName] = useState("");

  const getSummonerName = () => {
    if (user && user) {
      // find summoner.type === "main"
      const mainAccount = user?.Summoner?.find((summoner) => summoner.type === "main");
      setSummonerName(mainAccount.name);
    }
  }

  useEffect(() => {
    getSummonerName();
  }, [user])

  return (
    <>
      <div className="w-full  h-[80px] flex justify-center bg-gray relative">
        <div className="h-full w-[30%] bg-yellow absolute left-0 top-0"></div>
        <div className="flex max-w-[1500px] 2xl:px-0 px-[5%] h-full w-full flex">
          <div className="h-full w-[32.3%] 2xl:w-[41.3%] flex items-center relative bg-yellow justify-between">
            {user ?<Link to="/league/clash" className={`2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center hover:border-none summoner-triangle-full-right group 2xl:bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-black 2xl:hover:text-yellow group-bg uppercase`}>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 first-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 first-triangle-two cursor-pointer "></div>
              CLASH
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 first-triangle-fourth cursor-pointer "></div>
            </Link> :
            <div className="h-full 2xl:w-full w-[60px]"></div>}
            <Link to="/league/teams" className="2xl:w-full text-[18px] 2xl:h-full flex items-center justify-center summoner-triangle-full-right group 2xl:bg-yellow 2xl:hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-black 2xl:hover:text-yellow group-bg uppercase">
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 second-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow left-0 z-20 second-triangle-two cursor-pointer "></div>
              ÉQUIPES
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 second-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute 2xl:block hidden bg-yellow right-0 z-20 second-triangle-fourth cursor-pointer "></div>
            </Link>
          </div>
            <div className="box2 2xl:hidden"></div>
          <div className="h-full w-[67.7%] flex items-center justify-center">
            <Title className="text-black">Les équipes</Title>
          </div>
        </div>
      </div>
    </>
  );
}
