import joinClasses from "../../helpers/joinClasses"
import "./styles.css"

export default function Bandeau({className = ""}){
    return (
        <div className={joinClasses(className,"")}>
          <span className="txt t1 font-boogaloo text-[44px]"> BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; </span>
          <span className="txt t2 font-boogaloo text-[44px] text-yellow">  BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp; BISCOTTE FAMILY &nbsp;</span>  
        </div>
        
    )
}