import axios from 'axios';


export async function getAllSummoners(){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/summoner`
  
    );
    return response.data;
}

export async function getSummonerByName(name){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/summoner/${name}`
        );
        return response.data;
        
}


export async function updateSummonerAccount(payload) {
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/summoner/update`,
        payload
    );
        return response.data;
}