import joinClasses from "../../../helpers/joinClasses";

export function TextArea({
    placeholder,
    onChange,
    className = "",
    maxLength,
    defaultValue = "",
    classNameContainer = "",
}) {
    return (
        <div className={`flex flex-col gap-1 w-full ${classNameContainer}`}>
            <label className="font-poppins text-gray-medium text-sm pl-3">{placeholder}</label>
            <textarea
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={onChange}
                defaultValue={defaultValue}
                className={joinClasses(
                    className,
                    `w-full resize-none pt-2 pb-1 bg-white  border-yellow active:border-yellow outline-none border-2 rounded-lg font-medium font-poppins text-sm pl-3 text-gray-medium placeholder-gray-medium/[0.3]`
                )}
            />
        </div>
    );
}
