import { useState } from "react";
import { toast } from "react-toastify";
import { adminChampions, adminChampionsTypes, adminGetClashs, adminQueue, adminResetRank, adminSummonerSpells } from "../../api/admin/admin";
import { LongButton, LottieButton, Paragraph, Title } from "../../components/atoms";
import Template from "../../components/molecule/Template";
import { useUserContext } from "../../context";
import loadingAnimation from "../../assets/animations/loading.json";
import Lottie from "lottie-react";



export default function Admin() {

    const { user, setUser } = useUserContext();
    const [refreshLoadingChampions, setRefreshLoadingChampions] = useState(false);
    const [refreshLoadingQueues, setRefreshLoadingQueues] = useState(false);
    const [refreshLoadingSumms, setRefreshLoadingSumms] = useState(false);
    const [refreshChampionTypes, setRefreshChampionTypes] = useState(false);
    const [refreshLoadingClashs, setRefreshLoadingClashs] = useState(false);
    const [refreshLoadingtRanks, setRefreshLoadingRanks] = useState(false);


    const loadChampions = async () => {
        setRefreshLoadingChampions(true);
        setTimeout(async () => {

            const response = await adminChampions();
            if (response.error === false) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setRefreshLoadingChampions(false);
        }, 500);
    }

    const loadQueues = async () => {
        setRefreshLoadingQueues(true);
        setTimeout(async () => {
            const response = await adminQueue();
            if (response.error === false) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setRefreshLoadingQueues(false);
        }, 500);
    }


    const loadSummonerSpells = async () => {
        setRefreshLoadingSumms(true);
        setTimeout(async () => {
            const response = await adminSummonerSpells();
            if (response.error === false) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setRefreshLoadingSumms(false);
        }, 500);
    }

    const loadChampionTypes = async () => {
        setRefreshChampionTypes(true);
        setTimeout(async () => {
            const response = await adminChampionsTypes();
            if (response.error === false) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setRefreshChampionTypes(false);
        }, 500);
    }


    const resetAllRanks = async () => {
        setRefreshLoadingRanks(true);
        setTimeout(async () => {
            const response = await adminResetRank();
            if (response.error === false) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setRefreshLoadingRanks(false);
        }, 500);
    }

    const refreshAllSummoners = async () => {
        toast.success("refresh all Summoners")
    }

    const getAllClashs = async () => {
        setRefreshLoadingClashs(true);
        const response = await adminGetClashs();
        if (response.error === false) {
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
        setRefreshLoadingClashs(false);
    }

    return (
        <Template>
            <Title className="text-center my-10 text-4xl">ADMIN</Title>
            <div className="w-full flex justify-center">
                <div className="w-full grid grid-cols-12 gap-14 max-w-[1500px]">
                    {user?.role === "SUPERADMIN" && (
                        <div className="col-span-3 gap-4 flex flex-col items-center">
                            <Title className="mb-3">Data</Title>
                            <LottieButton onClick={loadChampions} state={refreshLoadingChampions} >Champions</LottieButton>
                            <LottieButton onClick={loadQueues} state={refreshLoadingQueues} >Queues</LottieButton>
                            <LottieButton onClick={loadSummonerSpells} state={refreshLoadingSumms} >Sorts d'invocateurs</LottieButton>
                            <LottieButton onClick={loadChampionTypes} state={refreshChampionTypes} >Types de champions</LottieButton>
                        </div>
                    )}
                    {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                        <div className="col-span-3 gap-4 flex flex-col items-center">
                            <Title className="mb-3">Summoner</Title>
                            <LottieButton onClick={resetAllRanks} state={refreshLoadingtRanks} >Réinitialiser classements</LottieButton>
                            <LongButton className="w-full" onClick={refreshAllSummoners}>Refresh all summoners</LongButton>
                        </div>
                    )}
                    {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                        <div className="col-span-3 gap-4 flex flex-col items-center">
                            <Title className="mb-3">Clash</Title>
                            <LottieButton onClick={getAllClashs} state={refreshLoadingClashs} >Nouveaux clashs</LottieButton>
                        </div>
                    )}
                </div>
            </div>
        </Template>
    );
}