import { useUserContext } from "../../../context"
import { LongButton, Paragraph, Title } from "../../atoms"
import LongWhiteButton from "../../atoms/buttons/LongWhiteButton"

export function VoteModal({ isOpen = false, data, onClick = () => { }, setIsOpen = () => { }, confirm = () => { } }) {

    const { summoner } = useUserContext();


    if (isOpen) {

        return (
            <div className="fixed w-full h-full top-0 left-0 z-20">
                <div onClick={() => { setIsOpen(false) }} className="absolute top-0 left-0 bg-black/[0.25] w-full h-full" >
                </div>
                <div className="w-full h-full flex justify-center items-center">
                    <div className="z-20 p-4 rounded-lg bg-white shadow">
                        <Title className="text-2xl text-center">{data?.name}</Title>
                        <Paragraph className="text-center mt-4">Voter pour cet invocateur au role de <strong> {data?.poste} </strong></Paragraph>
                        <div className="justify-between flex gap-5 items-center mt-5">
                            <LongButton onClick={() => { setIsOpen(false) }} className="!w-1/3 bg-white border-2 border-yellow !px-0 !py-1.5">Annuler</LongButton>
                            <LongButton onClick={() => {
                                confirm(
                                    { voterId: summoner?.id, poste: data?.poste, summonerId: data?.id, clashId: data?.clashId, token: localStorage.getItem("bifam-token") }
                                )
                            }} className="!w-2/3 !px-0">Valider</LongButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
