import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import { useSearchParams } from "react-router-dom";
import jungle from "../../../assets/roles/jungle.svg";
import { Paragraph, Title } from "../../atoms";

export function ClashHeader() {

  return (
    <>
      <div className="w-full  h-[80px] flex justify-center bg-gray relative">
        <div className="h-full w-[30%] bg-yellow absolute left-0 top-0"></div>
        <div className="flex max-w-[1500px] h-full w-full flex">
          <div className="h-full w-[41.3%] flex items-center justify-between">
            <Link to="/league/clash" className="w-full text-[18px] h-full flex items-center justify-center hover:border-none summoner-triangle-full-right group bg-yellow hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-yellow group-bg uppercase">
              <div className="h-full w-20 absolute bg-yellow left-0 z-20 first-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute bg-yellow left-0 z-20 first-triangle-two cursor-pointer "></div>
              CLASH
              <div className="h-full w-20 absolute bg-yellow right-0 z-20 first-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute bg-yellow right-0 z-20 first-triangle-fourth cursor-pointer "></div>
            </Link>
            <Link to="/league/teams" className="w-full text-[18px] h-full flex items-center justify-center summoner-triangle-full-right group bg-yellow hover:bg-gray-medium relative font-poppins font-bold text-gray-medium hover:text-yellow group-bg uppercase">
              <div className="h-full w-20 absolute bg-yellow left-0 z-20 second-triangle-one cursor-pointer "></div>
              <div className="h-full w-20 absolute bg-yellow left-0 z-20 second-triangle-two cursor-pointer "></div>
              ÉQUIPES
              <div className="h-full w-20 absolute bg-yellow right-0 z-20 second-triangle-third cursor-pointer "></div>
              <div className="h-full w-20 absolute bg-yellow right-0 z-20 second-triangle-fourth cursor-pointer "></div>
            </Link>
          </div>
          <div className="h-full w-[67.7%] flex items-center justify-center">
            <Title className="text-black">Compose ton équipe de clash</Title>
          </div>
        </div>
      </div>
    </>
  );
}
