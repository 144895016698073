import joinClasses from "../../../helpers/joinClasses";

export function AuthInput({
  placeholder,
  type = "text",
  onChange,
  className = "",
  autoComplete,
}) {
  return (
    <input
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      onChange={onChange}
      className={joinClasses(
        className,
        `w-full bg-white border-yellow active:border-yellow outline-none border-2 rounded-lg font-medium font-poppins text-sm pl-3 h-[38px] text-gray-medium`
      )}
    />
  );
}
