/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { loginUser, verifyToken } from "../api/auth/auth";
import { getMasteries } from "../api/league/masteries/masteries";
import { getSummonerByName } from "../api/league/summoner/summoner";

const UserContext = React.createContext({ user: null });
UserContext.displayName = "UserContext";

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [summoner, setSummoner] = useState("");
  const [smurf, setSmurf] = useState("");
  const [masteries, setMasteries] = useState([]);
  const [total, setTotal] = useState(0);


  const getSummoner = async (name) => {
    const response = await getSummonerByName(name);
    
    // to lowercase, remove white spaces, trim 
    const cleanedName = name.toLowerCase().replace(/\s/g, "").trim();

    if (response.length > 1) {
      const summoner = response.find(
        (item) => item.cleanedName === cleanedName
      );
      const smurf = response.find(
        (item) => item.cleanedName !== cleanedName
      );
      setSmurf(smurf);
      setSummoner(summoner);
      getMasteriesData(summoner.name);
    } else {
      setSummoner(response);
      getMasteriesData(response.name);
    }
  };

  const getMasteriesData = async (summoner) => {
    const response = await getMasteries(summoner);
    setMasteries(response.masteries);
    setTotal(response.total);
  };

  const loginTheUser = async (payload, token) => {
    setLoading(true);
    setStatus("pending");
    // payload.captcha = token
    const user = await loginUser(payload);
    if (!user.error) {
      localStorage.setItem("bifam-token", user.token);
      setUser(user);
      setStatus("connected");
      localStorage.setItem("bifam-connected", true);
      setLoading(false);
      return true;
    } else {
      toast.error(user.message);
      setStatus("nothing");
      localStorage.setItem("bifam-connected", false);
      setLoading(false);
      return false;
    }
  };

  const verifyTheToken = async () => {
    setLoading(true);
    const userToken = await verifyToken();
    if (userToken) {
      setUser(userToken);
      if (userToken?.Summoner?.length > 1) {
        const summoner = userToken?.Summoner?.find(
          (item) => item.type === "main"
        );
        const smurf = userToken?.Summoner?.find(
          (item) => item.type === "secondary"
        );
        setSmurf(smurf);
        setSummoner(summoner);
      } else if (userToken?.Summoner?.length === 1) {
        setSummoner(userToken.Summoner[0]);
      }
      localStorage.setItem("bifam-connected", true);
      setStatus("connected");
    }
    
    localStorage.setItem("bifam-connected", false);
    setLoading(false);
  }

  const getTierColor = (queue) => {
    switch (queue?.tier) {
      case "IRON":
        return "!text-rank-iron";
      case "BRONZE":
        return "!text-rank-bronze";
      case "SILVER":
        return "!text-rank-silver";
      case "GOLD":
        return "!text-rank-gold";
      case "PLATINUM":
        return "!text-rank-platinum";
      case "DIAMOND":
        return "!text-rank-diamond";
      case "MASTER":
        return "!text-rank-master";
      case "GRANDMASTER":
        return "!text-rank-grandmaster";
      case "CHALLENGER":
        return "!text-rank-challenger";
      default:
        return "!text-rank-unranked";
    }
  };

  const getRank = (queue) => {
    switch (queue?.tier) {
      case "IRON":
        return "Fer " + queue?.rank;
      case "BRONZE":
        return "Bronze " + queue?.rank;
      case "SILVER":
        return "Argent " + queue?.rank;
      case "GOLD":
        return "Or " + queue?.rank;
      case "PLATINUM":
        return "Platine " + queue?.rank;
      case "DIAMOND":
        return "Diamant " + queue?.rank;
      case "MASTER":
        return "Maitre";
      case "GRANDMASTER":
        return "Grand maitre";
      case "CHALLENGER":
        return "Challenger";
      default:
        return "Non classé";
    }
  };



  useEffect(() => {
    verifyTheToken();
  }, []);

  const stateValues = useMemo(
    () => ({
      user,
      setUser,
      smurf,
      setSmurf,
      loading,
      setLoading,
      loginTheUser,
      status,
      verifyTheToken,
      setStatus,
      getMasteries,
      getSummoner,
      setSummoner,
      summoner,
      masteries,
      getTierColor,
      getRank,
      total
    }),
    [user, setUser, summoner, smurf, getRank, getTierColor, setSmurf, setSummoner, masteries, loading, setLoading, setSummoner, loginTheUser, total, status, verifyTheToken, getSummoner, getMasteries, setStatus]
  );

  return (
    <UserContext.Provider value={stateValues}>{children}</UserContext.Provider>
  );
};
const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("Context was used outside of its Provider");
  }

  return context;
};

export { UserContextProvider, useUserContext, UserContext };
