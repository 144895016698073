import axios from 'axios';


export async function getMasteries(summonerName){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/masteries/${summonerName}`
  
    );
        return response.data;
}

