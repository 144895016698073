import {
    Route,
    BrowserRouter as Router,
    Routes,
    BrowserRouter,
    Navigate,
  } from "react-router-dom";
  import Login from "../pages/login.page";
  import Register from "../pages/register.page";
  import Home from "../pages/home.page";
  import League from "../pages/league/teams/league.page";
  import Summoner from "../pages/league/summoner/summoner.page";
  import Profile from "../pages/profile/profile.page";
  import ClashPage from "../pages/league/clash/clash.page";
  import Minecraft from "../pages/minecraft/minecraft.page";
  import Admin from "../pages/admin/admin.page";
  import { useUserContext } from "../context";
  import Page404 from "../pages/404.page";
  
  export function AuthRouter() {
  
    const { user } = useUserContext();
  
   
    return (  
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={user ? <Page404 /> : <Register />} />
          <Route path="/login" element={user ? <Page404 /> : <Login />} />
          <Route path="/league/teams" element={<League />} />
          <Route path="/league/summoner" element={<Summoner />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    );
  }
  