import joinClasses from "../../../helpers/joinClasses";
import { Paragraph } from "../texts/Paragraph";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";

export function LottieButton({ children, onClick, className = "", type = "button", state = true }) {

  return (
    <button
    onClick={onClick}
    type={type}
    className={joinClasses(
      className,
      `duration-200 w-full font-poppins font-medium py-2 px-7 rounded-lg text-gray-medium ${state ? "bg-white border-yellow border-2 flex items-center gap-2 justify-center py-0 h-10" : "bg-yellow "}`
      )}
      >
      
        {state && state ? <><Paragraph>Chargement</Paragraph> <Lottie className="h-14 overflow-hidden" animationData={loadingAnimation} /></> : children}
    </button>
  );
}
