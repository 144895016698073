import joinClasses from "../../../helpers/joinClasses";
import Footer from "../Footer";
import { ClashHeader } from "../Header/ClashHeader";
import Header from "../Header/Header";
import { LoggedHeader } from "../Header/LoggedHeader";
import { TeamHeader } from "../Header/TeamHeader";

export function TeamTemplate({ className = "", children }) {
    return (
        <div className={`flex flex-col min-h-screen `}>
            <Header/>
            <TeamHeader/>
            <div className={joinClasses(className, "")} style={{ "minHeight": "calc(100vh - 160px)" }}  >
                {children}
            </div>
            <Footer />
        </div>
    );
}
