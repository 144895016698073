import axios from 'axios';

export async function updateClashDispo(payload){
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/clash/update/disponibility`
        , payload
    );
        return response.data;
}


export async function getClashList(){
    const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LOCALURL}/clash`
    );
        return response.data;
}

