
import Template from "../components/molecule/Template";


export default function Page404() {
     
  return (
    <Template>
      <h1>404</h1>
    </Template>
  );
}
