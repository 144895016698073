import joinClasses from "../../../helpers/joinClasses";

export function ProfileCard({ className = "", title, children, classNameTitle = "", className2 = "" }) {
  return (
    <div
      className={joinClasses(
        className,
        `rounded-lg shadow relative flex flex-col items-center bg-gray pt-8 bg-gray pb-10 px-[30px]`
      )}
    >
      <h3 className={` ${classNameTitle} font-boogaloo text-[26px] mb-5 uppercase text-center text-gray-medium`}>
        {title}
      </h3>
      <div className={` ${className2} flex flex-col items-center w-full`}>
      {children}
      </div>
    </div>  
  );
}
