import joinClasses from "../../../helpers/joinClasses";

export function BorderedCard({ className = "", children, classNameTitle = "",  }) {
  return (
    <div
      className={joinClasses(
        className,
        `rounded-lg border-[3px] border-yellow bg-gray  `
      )}
    >
      {children}
    </div>
  );
}
