import { LongButton, NewAuthInput } from "../../atoms";
import { Card } from "../../molecule";

export function Minecraft({user}){


    return (
        <Card title="Minecraft" className="m-0 self-start !h-auto !w-[35%]">
                            <div className="h-full flex flex-col gap-10 justify-between">
                                <NewAuthInput defaultValue={user?.Minecraft?.name} placeholder="Pseudo Minecraft" className=""></NewAuthInput>
                                <LongButton>Enregistrer</LongButton>
                            </div>
                        </Card>
    )
}