import {
  Route,
  BrowserRouter as Router,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";

import Page404 from "../pages/404.page";

export function LoadingRouter() {


  return (  
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}
